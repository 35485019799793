import React from 'react';
import { Provider } from "react-redux";
import Four9ErsGame from './Containers/Four9ErsGame/Four9ErsGame'
import './App.css';
import store from "./store";

function App() {
  return (
   <Provider store={store}>
     <Four9ErsGame /> 
   </Provider>
  ); 
}

export default App;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router"
import { connect } from 'react-redux';
import { setNewToken, fetchUser } from '../../store/authReducer/actions';
import { showLoader, hideLoader } from "../../Components/Loader/Loader.js";

import './index.css';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Verify = ({ setToken, fetchCurrentUser, isProcessing, isAuthenticated, currentUser }) => {
    const query = useQuery();
    let history = useHistory();

    useEffect(() => {
        if(query.get("token") && query.get("token") !== ''){
            setToken(query.get("token"));
        }

        fetchCurrentUser();
    }, []);

    useEffect(() => {
        if (currentUser.id !== undefined) {
            history.push("/games");
        }
    }, [currentUser])

    if (!isProcessing && isAuthenticated !== undefined && !isAuthenticated) {
    window.location.href="/login";
        }
    return null
};

Verify.propTypes = {};

const mapStateToProps = ({ authReducer }) => ({
    isProcessing: authReducer.fetching,
    isAuthenticated: authReducer.authState,
    currentUser: authReducer.user
});

const mapDispatchToProps = {
    setToken: (token) => setNewToken(token),
    fetchCurrentUser: () => fetchUser()
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
import * as ACTIONS from "./action_types";
import api from "../../api";
import axios from "../../utils/axios/axios";

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseHint = ({
  id,
  content,
  gameId,
  title,
  puzzle
}) => ({
  id,
  content,
  gameId,
  title,
  puzzle
});

const parseHints = (hints) => {
  let hintsMap = [];
  
  hints.map((hint) => {
    let parsedHint = parseHint(hint);
    try {
      hintsMap[hint.title].push(parsedHint);
    } catch {
      hintsMap[hint.title] = [];
      hintsMap[hint.title].push(parsedHint);
    }
  })
  return hintsMap;
};
const parseHintTasks=(hintsTask)=>{

let hintsTaskMap=[]

hintsTask &&  hintsTask.length > 0 && hintsTask.map(hints=>{
  hints.hint_tasks.map(hint=>{
    let parsedHintTasks=parseHint(hint)
    try{
     hintsTaskMap[hints.title].push(hint)
    }catch{
     hintsTaskMap[hints.title]=[]
     hintsTaskMap[hints.title].push(hint)
    }
  })

})
return hintsTaskMap
}
export const fetchHints = () => {
  const url = api.customer.GET_CUSTOMER_HINTS;
  return (dispatch) => {
    dispatch(setProcessing(true));
    axios.get(url).then(({ data }) => {
      const { hints } = data; 
      const newHints = parseHints(hints);
      let newGates = []
      for(let k in newHints){
        newGates.push({title: k});
      }
      const newHintTasks = parseHintTasks(hints)
      
      let newGatesTask = []
      let newHintList =[]
      for(let k in newHintTasks){
        for(let item of newHintTasks[k]) {
          newGatesTask.push({title: item.title,item:item.hint_task_contents});
        }
      }
      dispatch({
        type: ACTIONS.FETCH_HINTS,
        payload: newHintTasks,
        // payload: {newHints,newHintTasks},
      });
      dispatch({
        type: ACTIONS.FETCH_GATES,
        payload: {newGates,newGatesTask},
      });
    });
  };
};

import React, { useEffect } from "react";
import CloseIcon from '../../assets/images/icon/X_Button.png'
import "./index.css";

const MessagesContent = ({ message }) => {
  useEffect(() => {

  }, []);

  if (message === -1) {
    return ""
  }
  return (
    <div className="msg-content">
      <div className="msg-list-item message-content-header selected-item">
        <span style={{letterSpacing:'1.3px'}}>{`SUBJECT:${message.subject}`}</span>
      </div>
      <div className="msg-text">
        {message.message}
          </div>
    </div>
  );
};

MessagesContent.propTypes = {
};

export default MessagesContent;

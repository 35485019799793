import React, { useState,useRef } from 'react'
import axios from "../../utils/axios/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../api";
import { useHistory } from "react-router"
import Modal from "../Model/Model"
import { showLoader, hideLoader } from "../../Components/Loader/Loader.js";
import './index.css';
import { Formik, Form, Field, ErrorMessage, connect } from 'formik';
import Header from "../../Components/Header/Header";
import HeaderImg from '../../assets/images/Header.png'
import HeaderMobileImg from '../../assets/images/Header-phone.png'
import * as Yup from 'yup'

const ForgotPassword = ({ history }) => {
    const [startDate, setStartDate] = useState('');
    let resetRegister = null;
    let resetCode = null;
    const [isValide, setValide] = useState(false)
    const [code, setCode] = useState()
    const [show, setShow] = useState()
    const [modelType, setModelType] = useState()
    const [cookieExpire, setCookieExpire] = useState();
    const [msg, setMsg] = useState('');
    const hideModal = () => {
        setShow(false)
    };
    const initialValues1 = {
        email: '',
        password: '',
    }
    // let history = useHistory();
    let inputEl = useRef(null);
    const onSubmit1 = (values) => {
        if (values) {
            let data = { email: values.email }
            const url = '/users/forgot-password';
            setMsg('')
            axios.post(url, data).then(({ status, data }) => {
               // history.push('/games')
                setMsg('Forgot password email sent!');
            }).catch(error => {
            });
        }
    }
    let itemTextClass = ''
    isValide ? itemTextClass = itemTextClass.concat('left-panel-form-group ') : itemTextClass = itemTextClass.concat('selected-left-panel-form-group')

    const validationSchema1 = Yup.object({
        email: Yup.string().email('*email Invalid')
            .required('*Required !')
    })


    return (
        <div>
            <div className="header-Login" style={{ backgroundImage: `url(${HeaderImg})` }}>
            </div>
            <div className="header-Login-mobile">
                <div className="section">
                    <div className="img" style={{ backgroundImage: `url(${HeaderMobileImg})` }}></div>
                </div>
            </div>
            <div className="login-wrapper">
                <div className="login-wrapper-2">
                    <div className="login-inner-wrapper" style={{margin: 'auto'}}>
                        <div className="login-container">
                            <h2>Forgot Password</h2>
                            <span>Enter your email to receive forgot password link</span>
                            <Formik
                                initialValues={initialValues1}
                                validationSchema={validationSchema1}
                                onSubmit={onSubmit1}
                            >
                                {formik => {
                                    return (<Form action="" >
                                        <div>
                                            <div className="errorDiv">
                                                <label>Email</label>
                                                <ErrorMessage name="email" component="div" className="errorColorLogin" />
                                            </div>
                                            <Field type="text" onChange={formik.handleChange} value={formik.values.email} name="email" placeholder="Enter your email here" autoComplete="off" />
                                        </div>
                                        <div className="login-btn">
                                            <button type="submit"> Forgot Password</button>
                                        </div>
                                        <div className="login-bottom">
                                            <div className="login-me">
                                                <a href="/login">Back to Login</a>
                                            </div>
                                        </div>
                                    </Form>)
                                }}
                            </Formik>
                            <span>{msg}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} handleClose={hideModal}>
                {modelType === "Login" ?
                    <div className="login-Error-Popup">
                        <span>Login Failed</span>
                        <a href="/login" >Try again</a>
                        <a href="#!">Forgot Password</a>
                    </div> : modelType === "Code" ? <div className="login-Error-Popup">
                        <span>Code Invalid</span>
                    </div> : modelType === "Register" ? <div className="login-Error-Popup">
                        <span>Registered Successfully</span>
                    </div> : <div className="login-Error-Popup"><span>email already used</span></div>
                }
            </Modal>
        </div>
    )
}

export default ForgotPassword

import * as ACTIONS from "./action_types";

const initialState = {
  fetching: false,
  success: false,
  failure: false,

  hints: [],
  gates: [],
  gatesTasks: []
};

const hintsReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PROCESSING:
      return {
        ...state,
        fetching: payload,
        success: false,
        failure: false,
      };
    case ACTIONS.FETCH_HINTS:
      return {
        ...state,
        fetching: false,
        success: true,
        failure: false,

        hints: payload,
      };
    case ACTIONS.FETCH_GATES:
      return {
        ...state,
        fetching: false,
        success: true,
        failure: false,

        gates: payload.newGates,
        gatesTasks: payload.newGatesTask,
      };
    default:
      return {
        ...state,
      };
  }
};

export default hintsReducer;

import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Vimeo from '@vimeo/player';
import { useHistory } from "react-router"

import { markVideoAsWatched } from '../../store/videosReducer/actions';

import useSound from 'use-sound';
import sfx from '../../utils/sfx';

const VideoModal = ({ videoId, title, url, markWatchedVideo, onVideoPlayerClose }) => {
  let history = useHistory();
  const videoFrame = useRef(null);
  const onCloseCallback = onVideoPlayerClose || null;

  const [playPopupOpen] = useSound(sfx.AUDIO_POPUP_OPEN);
  const [playPopupClose] = useSound(sfx.AUDIO_POPUP_CLOSE);

  useEffect(() => {
    let player = new Vimeo(videoFrame.current);

    player.on('play', function () {
      console.log('Played the video');
      playPopupOpen();
    });

    player.getVideoTitle().then(function (title) {
      console.log('title:', title);
    });

    player.on('ended', () => {
      markWatchedVideo(videoId);
      history.push("/games");
      playPopupClose();
      if (onCloseCallback) onCloseCallback();
    });

    return () => {
      player.off('play');
      player.off('ended');
    };
  }, []);

  const onEventClose = () => {
    markWatchedVideo(videoId);
    history.push("/games");
    playPopupClose();
    if (onCloseCallback) onCloseCallback();
  }

  return (
    <div id="videoBox" className="videoBox">
      <div className="video-close-btn" onClick={onEventClose}><i className="icon-close"></i></div>
      <iframe
        className='videoFrame'
        ref={videoFrame}
        title={title}
        src={`${url}?autoplay=1`}
        height="100%"
        width="100%"
        frameBorder="0"
        allowFullScreen
        allow="autoplay"
      ></iframe>
    </div>
  );
};

VideoModal.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  markWatchedVideo: (videoId) => markVideoAsWatched(videoId),
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal);

const COMMON_PREFIX = "store/gateReducer";

export const LOAD_NEW_GATE = `${COMMON_PREFIX}/LOAD_NEW_GATE`;

// Gate States
export const SAVE_GATE_STATE = `${COMMON_PREFIX}/SAVE_GATE_STATE`;
export const TEMP_LOCK_GATE = `${COMMON_PREFIX}/TEMP_LOCK_GATE`;
export const TEMP_UNLOCK_GATE = `${COMMON_PREFIX}/TEMP_UNLOCK_GATE`;

export const RESET_GAME = `${COMMON_PREFIX}/RESET_GAME`;
export const SAVE_ALL_SOLUTIONS = `${COMMON_PREFIX}/SAVE_ALL_SOLUTIONS`;

export const TOGGLE_RESET_STATE = `${COMMON_PREFIX}/TOGGLE_RESET_STATE`;
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';
import FileIcon from './FIleIcon';    
import FilesPopup from './FilesPopup';
import Popup from '../Popup/Popup'; 
import {fetchFiles, markFileAsRead} from '../../store/filesReducer/action';

const Files = ({ files, history, fetchAllFiles, readFile}) => {
  const [playPopupOpen] = useSound(sfx.AUDIO_POPUP_OPEN);
  const [playPopupClose] = useSound(sfx.AUDIO_POPUP_CLOSE);

  useEffect(() => {
    fetchAllFiles();
  }, []);

  const [selectedFile, setSelectedFile] = useState({});

  function onChangeSelectedFile(file) {
    if (selectedFile.url !== undefined) {
      playPopupClose();
      return setSelectedFile({});
    }
    if(!file.isRead) {
      readFile(file.fileId);
    }
    setSelectedFile(file);
    playPopupOpen();
  }

  const onClosePopup = e => {
    history.goBack();
  }

  return (
    <div className="files-container bigger-window">
      <Popup  headerBG="#58ac9d" >
        <div className="files-grid">
          {files.map((fileData) => {
            return (
              <FileIcon
                key={fileData.fileId}
                onClick={() => {
                  onChangeSelectedFile(fileData);
                }}
                title={fileData.name}
                iconImg={fileData.icon}
                selectedFile={selectedFile}
                isRead={fileData.isRead}
              />
            );
          })}
        </div>
          </Popup>
          {selectedFile.url !== undefined ? (
            <FilesPopup
              file={selectedFile}
              onClose={() => onChangeSelectedFile({})}
            />
          ) : (
            ''
          )}
    
        </div>
      );
    };

Files.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      isRead: PropTypes.bool,
      status: PropTypes.string,
      gameId: PropTypes.number,
      taskId: PropTypes.number,
      customerTaskId: PropTypes.number,
      fileId: PropTypes.number,
      customerId: PropTypes.number,
      name: PropTypes.string,
      pages: PropTypes.arrayOf(PropTypes.string),
      icon: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  onClosePopup: PropTypes.func
};

const mapStateToProps = ({ filesReducer }) => ({
  files: filesReducer.files,
});

const mapDispatchToProps = {
  fetchAllFiles: () => fetchFiles(),
  readFile: (fileId) => markFileAsRead(fileId)
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);

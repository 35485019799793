import React from 'react';

import './index.css';

import IconImg1 from "../../assets/images/svg/security.svg";
import IconImg2 from "../../assets/images/svg/playbook.svg";
import IconImg3 from "../../assets/images/svg/lookerRoom.svg";
import IconImg4 from "../../assets/images/svg/museum.svg";
import IconImg5 from "../../assets/images/svg/trophy.svg";
import IconImg6 from "../../assets/images/icongats/Icon_FAQ.png";
const HintGatesList = ({ gates, selectedGate, setSelectedGate, hintsHtml }) => {
    return (
        <div className="gates-list">
            <div className="gates-list-body">
                <ul className="gates-list-ul">
                    {gates && gates.length > 0 && gates.map((gate, index) => {
                        gate.image = (gate.title === "SECURITY OFFICE") ? IconImg1 : (gate.title === "PLAYBOOK") ? IconImg2 : (gate.title === "LOCKER ROOM") ? IconImg3 : (gate.title === "MUSEUM") ? IconImg4 : (gate.title === "FAQ") ? IconImg6 : IconImg5
                        let classes = selectedGate.title === gate.title ? "gate-list-item item-selected" : "gate-list-item";
                        classes += ` bg-color-0`;
                        return <React.Fragment key={index}> <li className={classes} onClick={() => { selectedGate.title === gate.title ? setSelectedGate({}) : setSelectedGate({ index: index, ...gate }) }}>
                            <div><img alt="icon" src={gate.image} /></div>
                            <div className="title">{gate.title}</div>
                        </li>
                            {gate.title === selectedGate.title ? <div className="d-d-none">{hintsHtml}</div> : ''}
                        </React.Fragment>
                            ;
                    })}
                </ul>
            </div>
        </div>
    );
};

HintGatesList.propTypes = {};

export default HintGatesList;
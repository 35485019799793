import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import { submitGateAnswer, saveGateState, toggleResetState,loadGate, resetGame } from '../../store/gateReducer/action';
import { showLoader, hideLoader } from "../../Components/Loader/Loader";
import {CONSTRUCT_URL} from "../../utils/env";

const messages = {
  LOAD_GATE: 'LOAD_GATE',
  NEW_GATE_STATE: 'NEW_GATE_STATE',
  RESET_PROGRESS: 'RESET_PROGRESS',
  ON_TAB_SWITCH: 'ON_TAB_SWITCH'
};

const constructMessages = {
  WINDOW_LOADED: 'WINDOW_LOADED',
  LOAD_GATE_STATE: 'LOAD_GATE_STATE',
  ON_SAVE_GATE_STATE: 'ON_SAVE_GATE_STATE',
  ON_GATE_SUBMIT: 'ON_GATE_SUBMIT',
  ON_RESET_GAME: 'ON_RESET_GAME',
  ON_CLOSE_POPUP: 'ON_CLOSE_POPUP',
  ON_TAB_SWITCH: 'ON_TAB_SWITCH',
  ON_GATE_LOADED: 'ON_GATE_LOADED',
  ON_FIELD_SECTION_CLICK: 'ON_FIELD_SECTION_CLICK'
};

const GateFrame = ({
  id,
  title,
  url,
  history,
  gateStates,
  gateSolutions,
  submitAnswer,
  saveNewGateState,
  gatesMenu,
  resetState,
  changeResetState,
  setToken,
  shouldDisplay,
  isGameComplete,
  loadNewGate
}) => {

  let myIframe = useRef(null);
  const constructMessagesListener = async(event) => {
    const { type, payload } = event.data;
    if (myIframe.current) {
      switch (type) {
        case constructMessages.WINDOW_LOADED:
          let prevGateState = gateStates[id] ? gateStates[id] : {};
          let gateSolution = gateSolutions[id] ? gateSolutions[id] : {};
          if (resetState) {
            console.log("sending '", messages.RESET_PROGRESS, "' to construct");
            myIframe.current.contentWindow.postMessage(
              {
                type: messages.RESET_PROGRESS,
                payload: {
                  gateId: 0
                },
              },
              url
            );
            changeResetState();
          }

          const gameProgress =  {
            "securityOffice": false,
            "playbook":false,
            "lockerRoom":false,
            "museum":false,
            "trophycase":false
            }
          gatesMenu.forEach(item => {
            switch (item.title) {
              case 'Security office':
                if(item.status === 'complete' ) gameProgress.securityOffice = true;
              case 'Playbook':
                if(item.status === 'complete' ) gameProgress.playbook = true;
              case 'Locker room':
                if(item.status === 'complete' ) gameProgress.lockerRoom = true;
              case 'Museum':
                if(item.status === 'complete' ) gameProgress.museum = true;
              case 'Trophy case':
                if(item.status === 'complete' ) gameProgress.trophycase = true;
              default:
                break;
            }
          });
          myIframe.current.contentWindow.postMessage(
            {
              type: messages.LOAD_GATE,
              payload: {
                gateId: id,
                gateState: JSON.stringify(prevGateState),
                gateSolution: JSON.stringify(gateSolution),
                gameProgress: JSON.stringify(gatesMenu)
              },
            },
            url
          );
        case constructMessages.LOAD_GATE_STATE:
          return (
            myIframe.current.contentWindow.postMessage({
              type: messages.NEW_GATE_STATE,
              payload: {
                gateState: JSON.stringify(gateStates[id])
              },
            }),
            url
          );
        case constructMessages.ON_RESET_GAME:
          //need to remove user game data with API
          console.log("RESETTING CALLBACK SENT!");

          return (
            myIframe.current.contentWindow.postMessage({
              type: messages.RESET_PROGRESS,
              payload: {},
            }),
            url
          );
        case constructMessages.ON_GATE_SUBMIT:
          // const userAnswer = JSON.parse(payload.answer);
          let ID=payload.gateID
          const userAnswer = JSON.parse(payload.answer);
          const answer = JSON.parse(userAnswer.answer);

          submitAnswer(ID, { isAnswerValid: true, answer });
          return window.removeEventListener('message', constructMessagesListener);
        case constructMessages.ON_SAVE_GATE_STATE:

          const newGateState = JSON.parse(payload.gateState);
          const saveGateID = JSON.parse(payload.gateID);
          await saveNewGateState(saveGateID, newGateState);
          return;
        case constructMessages.ON_CLOSE_POPUP:
          history.goBack();
          return;
        case constructMessages.ON_GATE_LOADED:
          hideLoader()
          return;
        //stop loader
        case constructMessages.ON_FIELD_SECTION_CLICK:  
          gatesMenu.forEach(item => {
           if(item.gameId == payload.gateID && item.status != "locked") {
            loadNewGate("", payload.gateID)      
           }
          });  
          return;
        default:
          return;
      }
    }
  };

  const addListeners = () => {
    // Listener
    window.addEventListener('message', constructMessagesListener);
    return () => {
      window.removeEventListener('message', constructMessagesListener);
    };
  }

  useEffect(() => {
    if (id !== null)
      return addListeners();
  }, [id]);

  useEffect(() => {
    console.log("reset changed!", resetState);
    if (myIframe.current && resetState) {
      console.log("SENDING RESET CALLBACK!");
      myIframe.current.contentWindow.postMessage(
        {
          type: messages.RESET_PROGRESS,
          payload: {},
        },
        url
      );
      changeResetState();
      window.location.reload();
    }
  }, [resetState]);

  useEffect(() => {
    if (myIframe.current) {
      let prevGateState = gateStates[id] ? gateStates[id] : {};
      let gateSolution = gateSolutions[id] ? gateSolutions[id] : {};
      const gameProgress =  {
        "securityOffice": false,
        "playbook":false,
        "lockerRoom":false,
        "museum":false,
        "trophycase":false
        }
      gatesMenu.forEach(item => {
        switch (item.title) {
          case 'Security office':
            if(item.status === 'complete' ) gameProgress.securityOffice = true;
          case 'Playbook':
            if(item.status === 'complete' ) gameProgress.playbook = true;
          case 'Locker room':
            if(item.status === 'complete' ) gameProgress.lockerRoom = true;
          case 'Museum':
            if(item.status === 'complete' ) gameProgress.museum = true;
          case 'Trophy case':
            if(item.status === 'complete' ) gameProgress.trophycase = true;
          default:
            break;
        }
      });
      myIframe.current.contentWindow.postMessage(
        {
          type: messages.LOAD_GATE,
          payload: {
            gateId: id,
            gateState: JSON.stringify(prevGateState),
            gateSolution: JSON.stringify(gateSolution),
            gameProgress: JSON.stringify(gatesMenu)
          },
        },
        url
      );
      showLoader()
      // myIframe.current.contentWindow.postMessage({
      //   type: messages.ON_TAB_SWITCH,
      //   payload: {},
      // }, url)
    }
  }, [id]);

  return (
    <div className="gameplayer-main-container">
    <div className="gameplayer-container">
      {(gateSolutions[id] && gatesMenu || id == 0) ?
        <iframe
          ref={myIframe}
          title={`Four9Ers-${title}`}
          src={url}
          className="responsive-iframe"
        /> : isGameComplete ? <div className="congrats-sections"></div> : <p>Loading...</p>}
    </div></div>
  );
};

GateFrame.defaultProps = {
  //DANGER: THIS IS CHANGED:
  // url: 'https://www.google.com/',
  // url: 'http://localhost:3000/games',
  url: CONSTRUCT_URL,
};

GateFrame.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  url: PropTypes.string,
  gateStates: PropTypes.object,
};

const mapStateToProps = ({ gateReducer, menuReducer }) => ({
  id: gateReducer.gateId,
  resetState: gateReducer.resetState,
  title: gateReducer.gateTitle,
  gateStates: gateReducer.gateStates,
  gateSolutions: gateReducer.gateSolutions,
  gatesMenu: menuReducer.gates,
  isGameComplete: menuReducer.isGameComplete
});

const mapDispatchToProps = {
  submitAnswer: (gateId, userAnswer) => submitGateAnswer(gateId, userAnswer),
  saveNewGateState: (gateId, gatePayload) => saveGateState(gateId, gatePayload),
  changeResetState: () => toggleResetState(),
  loadNewGate: (gateTitle, gateId) => loadGate(gateTitle, gateId)
};

const arePropsEqual = (preProps, nextProps) => preProps.id === nextProps.id;

export default connect(mapStateToProps, mapDispatchToProps)(GateFrame, arePropsEqual);

import * as ACTIONS from "./action_types";

const initialState = {
  isLoading: false
};

const loadingReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.ADD_LOADER:
      return {
        ...state,
        isLoading: true
      };
    case ACTIONS.HIDE_LOADER:
        return {
          ...state,
          isLoading: false
        };
    default:
      return {
        ...state,
      };
  }
};

export default loadingReducer;

import * as ACTIONS from './action_types';

const initialState = {
  fetching: false,
  success: false,
  failure: false,

  videos: [],

  unwatchedVideo: null,
};

const videosReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PROCESSING:
      return {
        ...state,
        fetching: payload,
        success: false,
        failure: false,

        unwatchedVideo: null,
      };
    case ACTIONS.FETCH_VIDEOS:
      return {
        ...state,
        fetching: false,
        success: true,
        failure: false,

        videos: payload.customerVideos,
        unwatchedVideo: payload.unwatchedVideo,
      };
    default:
      return {
        ...state,
      };
  }
};

export default videosReducer;

const SERVICE = '/files';

const MARK_FILE_AS_READ = `${SERVICE}/read`;
const GET_FILE_BY_ID = `${SERVICE}/`;
const DOWNLOAD_FILE_BY_ID = `${SERVICE}/download`;

const files = {
    MARK_FILE_AS_READ,
    GET_FILE_BY_ID,
    DOWNLOAD_FILE_BY_ID
};

export default files;

import * as ACTIONS from "./action_types";

const initialState = {
  fetching: false,
  success: false,
  failure: false,

  files: [],
};

const filesReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PROCESSING:
      return {
        ...state,
        fetching: payload,
        success: false,
        failure: false,
      };
    case ACTIONS.FETCH_FILES:
      return {
        ...state,
        fetching: false,
        success: true,
        failure: false,
        totalUnread: payload.total_unread,
        files: payload.files,
      };
    default:
      return {
        ...state,
      };
  }
};

export default filesReducer;

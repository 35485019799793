import React, { useState, useEffect } from 'react';
import './index.css';
import { connect } from 'react-redux';
import Popup from '../Popup/Popup';
import goldLine from '../../assets/images/Gold_Line.png';
import missionAccomplished from '../../assets/images/Mission_Accomplished.png';
import Trophies from '../../assets/images/CongratsTrophies.png';
import RedGradient from '../../assets/images/Red_Gradient.png';
import Glow from '../../assets/images/Glow.png';
import TEGLogo from '../../assets/images/TEG.png';
import Confetti from '../../assets/images/Confetti.png';
import { playGameWinSFX } from '../../store/menuReducer/actions';

const CongratsScreen = ({ history, playWinSFX }) => {
  const [congratsPopup, setCongratsPopup] = useState(true);
  const onClosePopup = (e) => {
    setCongratsPopup(false);
  };
  useEffect(() => {
    playWinSFX();
  }, [])

  if (!congratsPopup) {
    return null;
  }

  return (
    <React.Fragment>
      <div className='congrats-popup'>
        <Popup
          headerBG='#1d2460'
          fontColor='#fff'
          onEventClose={(e) => onClosePopup(e)}
        >
          <div className='congrats-content-bg'>
            <div className='con-text-top'>Congratulations</div>
            <img className='con-img-top' src={goldLine} />
            <img className='con-img-mis' src={missionAccomplished} />
            <div className='con-text-bottom'>
              all championship trophies have been recovered.
              <br />
              Thanks for being{' '}
              <span className='con-text-bold'>faithful to the bay.</span>
            </div>
            <img className='con-img-trophies' src={Trophies} />
            <img className="con-img-glow" src={Glow} />
            <img className="con-img-red" src={RedGradient} />
            <div className="con-text-footer">
              Presented By <img src={TEGLogo}/>
            </div>
            <img className="con-img-confetti" src={Confetti} />
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

CongratsScreen.propTypes = {};

const mapStateToProps = ({ }) => ({
  
});

const mapDispatchToProps = {
  playWinSFX: () => playGameWinSFX(),
};

export default connect(mapStateToProps, mapDispatchToProps)(CongratsScreen);

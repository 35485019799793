import * as ACTIONS from "./action_types";

const initialState = {
  fetching: false,
  success: false,
  failure: false,
  totalUnread: 0,
  messages: [],
};

const messagesReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PROCESSING:
      return {
        ...state,
        fetching: payload,
        success: false,
        failure: false,
      };
    case ACTIONS.FETCH_MESSAGES:
      return {
        ...state,
        fetching: false,
        success: true,
        failure: false,

        messages: payload.messages,
        totalUnread: payload.totalUnread
      };
    default:
      return {
        ...state,
      };
  }
};

export default messagesReducer;

import * as ACTIONS from './action_types';
// import * as GATE_ACTIONS from '../gateReducer/action_types';
import api from '../../api';
import axios from '../../utils/axios/axios';
import sfx from '../../utils/sfx';

import { fetchVideos } from '../videosReducer/actions';
// import { addLoader, hideLoader } from '../loadingReducer/actions';
import { getAllSolutions, loadGate } from '../gateReducer/action';
import { fetchFiles } from '../filesReducer/action';
import { fetchMessages } from '../messagesReducer/actions';

export const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseTask = ({
  answer,
  id,
  title,
  status,
  gameId,
  taskId,
  customerId,
}) => ({
  answer,
  id,
  title,
  status,
  gameId,
  taskId,
  customerId,
  isLocked: status === 'locked',
  isComplete: status === 'complete',
});

const parseTasks = (tasks) => tasks.map((task) => parseTask(task));

export const fetchGates = () => {
  const url = api.customer.GET_CUSTOMER_TASKS;
// const url = "/customers/tasks/"
  return (dispatch) => {
    // dispatch(addLoader());
    dispatch(getAllSolutions());
    dispatch(setProcessing(true));
    dispatch(fetchVideos());
    dispatch(fetchFiles());
    dispatch(fetchMessages());
    axios
      .get(url)
      .then(({ status, data }) => {
        const { tasks } = data;
        const gates = parseTasks(tasks);
        dispatch({
          type: ACTIONS.FETCH_GATES,
          payload: gates,
        });
        const lastUnlockedGate = gates.find(
          (gate) => gate.status === 'unlocked'
        );
        if(!lastUnlockedGate){
          dispatch({
            type: ACTIONS.GAME_COMPLETE
          });
          dispatch(loadGate("Field", 0));   
        }else{
          
          const gameProgress =  {
            "securityOffice": false,
            "playbook":false,
            "lockerRoom":false,
            "museum":false,
            "trophycase":false
            }
            gates.forEach(item => {
              switch (item.title) {
                case 'Playbook':
                  if(item.status === 'locked') gameProgress.playbook = true;
                case 'Locker room':
                  if(item.status === 'locked') gameProgress.lockerRoom = true;
                case 'Museum':
                  if(item.status === 'locked') gameProgress.museum = true;
                case 'Trophy case':
                  if(item.status === 'locked') gameProgress.trophycase = true;
                default:
                  break;
              }
          });
          if(gameProgress.playbook == true && gameProgress.lockerRoom == true && gameProgress.museum == true && gameProgress.trophycase == true) {
            dispatch(loadGate("Field", 0));    
          } else {
            if(lastUnlockedGate.title === "Playbook") {
              dispatch(loadGate(lastUnlockedGate.title, lastUnlockedGate.gameId));   
            }
             
          }
        }
        
        // dispatch(hideLoader());
      })
      .catch((e) => {
        // dispatch(hideLoader());
        console.log(e);
      });
  };
};


export const markGameIncomplete = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.GAME_INCOMPLETE
    });
  }
}

export const playGameWinSFX = () => {
  return (dispatch) => {
    dispatch(playGameCompleteSound);
  }
}

const playGameCompleteSound = () => {
  const audio = new Audio(sfx.AUDIO_GAME_WIN)
  audio.play()
}
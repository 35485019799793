import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import {withRouter} from "react-router";
import {compose} from 'redux';
import { connect } from 'react-redux';
import {useLocation} from 'react-router-dom';
import {setNewToken,validateUser} from '../../store/authReducer/actions';
import FrameLayout from "../../Components/GateFrame/FrameLayout";
import Four9ErsDashboard from "../Four9ErsDashboard/Four9ErsDashboard";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Four9ErsRout = ({ setToken,checkUser,authUserValid, authenticatedUser, component: Component, ...rest }) => {
  const query = useQuery();
  useEffect(() => {
    if(query.get("token") && query.get("token") !== ''){
        setToken(query.get("token"));
    }
    checkUser();
}, []);
  if(authUserValid === undefined){
    return null;
  } 
   if(!authUserValid){
    window.location.href="/login";
    return null;
  }

  if(authenticatedUser.id !== undefined){
  return (
    <Route
      {...rest}
      render={(props) => (
        <Four9ErsDashboard>
          <FrameLayout shouldDisplay={props.location.pathname === '/games'} {...props} />
          <Component {...props} />
        </Four9ErsDashboard>
      )}
    />
  );
}else{
  const token = query.get("token") && query.get("token") !== '' ? `?token=${query.get("token")}` : '';
  return <Redirect to={`/${token}`} />
}

};


Four9ErsRout.propTypes = {};

const mapStateToProps = ({ authReducer }) => ({
  authenticatedUser: authReducer.user,
  authUserValid:authReducer.isUserValid
});
  
const mapDispatchToProps = {
  setToken: (token) => setNewToken(token),
  checkUser:()=>validateUser(),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Four9ErsRout);
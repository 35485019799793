import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GateFrame from './GateFrame';
import { loadGate, toggleResetState } from "../../store/gateReducer/action";

const FrameLayout = (props) => {
  useEffect(() => { 
  }, [props.resetState]);
    return <div style={props.shouldDisplay ? {display: 'contents'} : {display: 'none'}}>
        <GateFrame {...props} />
    </div>
}

const mapStateToProps = ({ gateReducer }) => ({resetState: gateReducer.resetState});
    
  const mapDispatchToProps = {
    resetGateId: () => loadGate('', null),
    changeResetState: () => toggleResetState(),
  };
  
  export default 
    connect(mapStateToProps, mapDispatchToProps)
  (FrameLayout);

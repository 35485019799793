import React, {useState, useRef, useEffect} from 'react'
import axios from "../../utils/axios/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../api";
import { useHistory } from "react-router"
import Modal from "../Model/Model"
import { showLoader, hideLoader } from "../../Components/Loader/Loader.js";
import './index.css';
import { Formik, Form, Field, ErrorMessage, connect } from 'formik';
import Header from "../../Components/Header/Header";
import HeaderImg from '../../assets/images/Header.png'
import HeaderMobileImg from '../../assets/images/Header-phone.png'
import * as Yup from 'yup'
import {withRouter, useParams} from "react-router";

const ResetPassword = ({ history }) => {
    const params = useParams();
    const [isValide, setValide] = useState(false)
    const [show, setShow] = useState()
    const [modelType, setModelType] = useState()
    const [msg, setMsg] = useState('');
    const hideModal = () => {
        setShow(false)
    };
    const initialValues1 = {
        confirmPassword: '',
        password: '',
    }

    // let history = useHistory();
    let inputEl = useRef(null);
    const onSubmit1 = (values) => {
        if (values) {


            setMsg('');
            if(values.password.length < 6) {
                setMsg('Password should be of min 6 length');
            } else if (values.password !== values.confirmPassword) {
                setMsg('Password and confirm password do not match');
            } else {
                setMsg('Please wait...');
                let data = { password: values.password,token: params.token }
                const url = '/users/change-password';
                setMsg('')
                axios.post(url, data).then(({ status, data }) => {
                    history.push('/login');
                    setMsg('Forgot password email sent!');
                }).catch(error => {
                    setMsg('Try with new forgot password email attempt. Session expired');
                });
            }
        }
    }
    let itemTextClass = ''
    isValide ? itemTextClass = itemTextClass.concat('left-panel-form-group ') : itemTextClass = itemTextClass.concat('selected-left-panel-form-group')

    const validationSchema1 = Yup.object({
        password: Yup.string().required('*Required !'),
        confirmPassword: Yup.string().required('*Required !')
    })


    return (
        <div>
            <div className="header-Login" style={{ backgroundImage: `url(${HeaderImg})` }}>
            </div>
            <div className="header-Login-mobile">
                <div className="section">
                    <div className="img" style={{ backgroundImage: `url(${HeaderMobileImg})` }}></div>
                </div>
            </div>
            <div className="login-wrapper">
                <div className="login-wrapper-2">
                    <div className="login-inner-wrapper" style={{margin: 'auto'}}>
                        <div className="login-container">
                            <h2>Reset Password</h2>
                            <Formik
                                initialValues={initialValues1}
                                validationSchema={validationSchema1}
                                onSubmit={onSubmit1}
                            >
                                {formik => {
                                    return (<Form action="">
                                        <div>
                                            <div className="errorDiv">
                                                <label>Password</label>
                                                <ErrorMessage name="password" component="div" className="errorColorLogin" />
                                            </div>
                                            <Field type="password" onChange={formik.handleChange} value={formik.values.password} name="password" placeholder="Enter your password here" autoComplete="off" />
                                        </div>
                                        <div>
                                            <div className="errorDiv">
                                                <label>Confirm Password</label>
                                                <ErrorMessage name="confirmPassword" component="div" className="errorColorLogin" />
                                            </div>
                                            <Field type="password" onChange={formik.handleChange} value={formik.values.confirmPassword} name="confirmPassword" placeholder="Enter your confirm password here" autoComplete="off" />
                                        </div>
                                        <div>   <span>{msg}</span>
                                        </div>
                                        <div className="login-btn">
                                            <button type="submit"> Set Password</button>
                                        </div>
                                        <div className="login-bottom">
                                            <div className="login-me">
                                                <a href="/login">Back to Login</a>
                                            </div>
                                        </div>
                                    </Form>)
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} handleClose={hideModal}>
                {modelType === "Login" ?
                    <div className="login-Error-Popup">
                        <span>Login Failed</span>
                        <a href="/login" >Try again</a>
                        <a href="#!">Forgot Password</a>
                    </div> : modelType === "Code" ? <div className="login-Error-Popup">
                        <span>Code Invalid</span>
                    </div> : modelType === "Register" ? <div className="login-Error-Popup">
                        <span>Registered Successfully</span>
                    </div> : <div className="login-Error-Popup"><span>email already used</span></div>
                }
            </Modal>
        </div>
    )
}

export default withRouter(ResetPassword)

const TASK_SERVICE = "/tasks";

const SUBMIT_TASK_ANSWER = `${TASK_SERVICE}/submit`;
const VISIT_TASK = `${TASK_SERVICE}/visit`;

const task = {
    SUBMIT_TASK_ANSWER,
    VISIT_TASK
};

export default task;

import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import { connect } from 'react-redux';
import './index.css';
import useSound from 'use-sound';
import sfx from '../../utils/sfx';
import Popup from '../Popup/Popup';
import { resetGame } from '../../store/gateReducer/action';
import { setNewToken, updateUser, AUTH_URL, setTokenInCookies } from '../../store/authReducer/actions';
import {SERVER_BASE_URL} from '../../utils/env';

import CameraIcon from '../../assets/images/Camera_Icon.png'
const Settings = ({ history, doResetGame, currentUser, doAbandonGame, doUpdateProfile, resetState }) => {
    const [play] = useSound(sfx.AUDIO_ACTION_CLICK);
    const [formData, setFormData] = useState({});
    const [image, setImage] = useState()
    const [msg, setMsg] = useState('');
    const [promptMsg, setPromptMsg] = useState('');
    const handleFileUpload = (event) => {
        const target = event.target
        if (target.files && target.files[0]) {
            /*Maximum allowed size in bytes
              5MB Example
              Change first operand(multiplier) for your needs*/
            const maxAllowedSize = 2 * 1024 * 1024;
            if (target.files[0].size > maxAllowedSize) {
                setMsg('Max 2MB allowed');
                // Here you can ask your users to load correct file
                target.value = ''
            } else {
                setImage(event.target.files.item(0))
                setMsg('Uploading...')
                fileUpload(event.target.files.item(0)).then((res) => {
                    setFormData({...formData, picture: res.data.url});
                    setMsg('');
                });
            }
        }
    }
    const onChangePassword = () => {
        history.push('/change-password')
    }

    useEffect(() => {
        setFormData({
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            email: currentUser.email,
            zipCode: currentUser.zipCode || '',
            picture: currentUser.picture
        });
    }, []);

    const onFormChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        if(resetState) {
            //window.location.reload();
        }
    }, [resetState])

    const onEventUpdateProfile = e => {
        e.preventDefault();
        setPromptMsg('Please wait...')
        doUpdateProfile(formData);
        setTimeout(() => {
            setPromptMsg('Changes saved successfully');
        }, 4000);
    }

    const fileUpload  = (file) => {
        const url = `${SERVER_BASE_URL}/api/s3`;
        const formData = new FormData();
        formData.append('file',file)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setMsg(`Uploading... ${percentCompleted}%`)
            }
        }
        return  post(url, formData,config)
    }

    const onClosePopup = e => {
        history.goBack();
    }

    const onEventResetGame = e => {
        e.preventDefault();
        doResetGame();
        play();
    }

      const onEventAbandonMission = e => {
          e.preventDefault();
          doAbandonGame();
          window.location.replace(AUTH_URL);
      }

    return (
        <div className="files-container settings-container">
            <Popup title="Settings | Profile" headerBG="#000" fontColor="#fff">
                <div className="setting-main-panel">
                            <form onChange={onFormChange} >
                                <div className="setting-panel">
                                    <div className="setting-right-panel">
                                    {msg !== '' ? <div className='upload-photo-text'>{msg}</div> : null}
                                            <input
                                            style={{display:"none"}}
                                            id="file-upload"
                                                name="file"
                                                type="file"
                                                onChange={handleFileUpload}
                                            />
                                            {formData.picture &&<img src={formData.picture} className="mt-3 height-img" />}
                                            <label htmlFor="file-upload" className="file-upload-section">
                                            <img src={CameraIcon} alt="Camera Icon" className="camera-icon"></img>
                                       Upload Photo
                                    </label>
                                    </div>
                                   
                                    <div className="setting-left-panel">
                                        <div className="form-group">
                                            <div className="form-1">
                                                <div className="errorDiv">
                                                    <label>First name</label>
                                                </div>
                                                <input type="text" name="firstName" defaultValue={formData.firstName}  autoComplete="off" />
                                            </div>
                                            <div className="form-2">
                                                <div className="errorDiv">
                                                    <label>Last name</label>
                                                </div>
                                                <input type="text" name="lastName" defaultValue={formData.lastName} placeholder="Last name" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-1 w-100">
                                                <div className="errorDiv">
                                                    <label>Email</label>
                                                </div>
                                                <input type="text" name="email" defaultValue={formData.email} disabled={true} placeholder="Email" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{color: 'white', textAlign: 'center', marginBottom: '7px'}}>{promptMsg}</div>
                                <div className="save-changes-btn">
                                    <input onClick={onEventUpdateProfile} className="saveChangesButton" type="submit" name="save" value="Save Changes" />
                                </div>
                                <div className="button-section">
                                    <input  onClick={onChangePassword}  type="submit" name="save" value="Change Password" />
                                    <input onClick={onEventResetGame} type="submit" name="save" value="Reset Game" />
                                    <input onClick={onEventAbandonMission} type="submit" name="save" value="Log out" />
                                </div>
                            </form>
                </div>
            </Popup>
        </div>
    );
};

Settings.propTypes = {
};

const mapStateToProps = ({ authReducer, gateReducer }) => ({
    currentUser: authReducer.user,
    resetState: gateReducer.resetState
});

const mapDispatchToProps = {
    doResetGame: () => resetGame(),
    doAbandonGame: () => setNewToken(''),
    doUpdateProfile: (prof) => updateUser(prof)
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
import React from 'react';
import './index.css';

import Popup from '../Popup/Popup';

const VideoPopup = ({ title, url, onClose }) => {
  return (
    <div className="video-popup">
      <Popup
        title={title}
        headerBG="#323b92"
        fontColor="#fff"
        onEventClose={onClose}
      >
        <div className="videoplayer-container">
          <iframe
            title={title}
            className="responsive-iframe"
            src={url + '??texttrack=en&color=ffffff&badge=0'}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </Popup>
    </div>
  );
};

VideoPopup.propTypes = {};

export default VideoPopup;

export function showLoader() {
    const loader = document.getElementsByClassName('preloader-outer')[0];
    if (!loader.classList.contains("show")) {
        loader.classList.add("show");
    }
}


export function hideLoader() {
    const loader = document.getElementsByClassName('preloader-outer')[0];
    if (loader.classList.contains("show")) {
        loader.classList.remove("show");
    }
}
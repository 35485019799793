import React, { useEffect } from "react";
import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';

const Popup = ({ children, title, headerBG, fontColor, onEventClose }) => {
    useEffect(() => {

    }, []);

    const [playPopupClose] = useSound(sfx.AUDIO_POPUP_CLOSE);

    const onClose = () => {
        playPopupClose();
        onEventClose();
    }

    return (
        <div className="popup-3d">
            <div className="popup-container">
                {/* {onEventClose && (<div className="popup-header" >
                    <div className="popup-title" style={{ color: fontColor }}>{title}</div>
                </div>)} */}
                <div className="popup-body">
                    <div className="popup-title" style={{ color: fontColor }}>{title}</div>
                    {onEventClose && (<div className="popup-close-btn" style={onEventClose ? {} : { display: 'none' }} onClick={onClose}><i className="icon-close"></i></div>)}
                    {children}
                </div>
            </div>
        </div>
    );
};

Popup.propTypes = {
};

export default Popup;
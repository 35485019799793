import React from 'react';
import './index.css';

import rightArrowIcon from '../../assets/images/svg/arrow.svg';

const HintsList = ({ hints, selectedGate, selectedHintTask, selectedHint, setSelectedHint, hintContentHtml }) => {
    return (
        <div className="" style={selectedGate.title === undefined ? { display: 'none' } : {}}>
            <div className="hints-list-section-container">
                <div className="hints-list-section-body">
                    <ul className="hints-list-section-ul">
                        {hints && hints.length > 0 ? hints.map((hints, index) => {
                            return (selectedHint.title === hints.title && hints || selectedHint.hintId === 6 ) && hints.hint_task_contents.length > 0 && hints.hint_task_contents.map((hint,index) => {
                                const classes = selectedHintTask.title === hint.title ? "item-selected" : "";
                                return <React.Fragment key={index}> <li onClick={() => { selectedHintTask.title === hint.title ? setSelectedHint({}) : setSelectedHint({ index: index, ...hint }) }} className={classes}>
                                    <div>{hint.title}</div>
                                    <div><img alt="Right Arrow" className="hints-list-section-ul-icon" src={rightArrowIcon} /></div>
                                </li>
                                    {selectedHintTask.title === hint.title ? <div className="d-d-block">{hintContentHtml} </div> : ''}
                                </React.Fragment>
                            })
                        }) : ''}  
                    </ul>
                </div>
            </div>
        </div>
    );
};

HintsList.propTypes = {};

export default HintsList;
import * as ACTIONS from "./action_types";
import api from "../../api";
import axios from "../../utils/axios/axios";
import sfx from '../../utils/sfx';

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseFile = ({
  id,
  isRead,
  status,
  gameId,
  taskId,
  customerTaskId,
  fileId,
  customerId,
  file: { pages, name, url, icon },
}) => ({
  id,
  isRead,
  status,
  gameId,
  taskId,
  customerTaskId,
  fileId,
  customerId,
  name,
  pages,
  icon,
  url,
});

const parseFiles = (files) => files.map((file) => parseFile(file));

export const fetchFiles = () => {
  const url = api.customer.GET_CUSTOMER_FILES;
  return (dispatch, getState) => {
    const oldUnreadFilesCount = getState().filesReducer.totalUnread;
    dispatch(setProcessing(true));
    axios.get(url).then(({ status, data }) => {
      const { customerFiles, total_unread } = data;
      const files = parseFiles(customerFiles);
      dispatch({
        type: ACTIONS.FETCH_FILES,
        payload: {files, total_unread},
      });
      if(total_unread > oldUnreadFilesCount) {
        playNewFileSound();
      }
    });
  };
};

export const markFileAsRead = (fileId) => {
  const url = `${api.files.MARK_FILE_AS_READ}/${fileId}`;

  return (dispatch) => {
    dispatch(setProcessing(true));
    axios
      .put(url)
      .then(({ status, data }) => {
        dispatch(setProcessing(false));
        dispatch(fetchFiles());
      })
      .catch((err) => console.log(err));
  };
};


export const fetchFile = async (fileId) => {
  const url = `${api.files.GET_FILE_BY_ID}/${fileId}`;
  const {data} = await axios.get(url);
  const file = parseFile(data);
  return file;
};

export const downloadFile = (fileId) => {
  const url = `${api.files.DOWNLOAD_FILE_BY_ID}/${fileId}`;
  return () => { 
    const {data} = axios.get(url);
    return data;
  }
};

const playNewFileSound = () => {
  const audio = new Audio(sfx.AUDIO_NEW_FILE)
  audio.play()
}
const CUSTOMERS_SERVICE = "/customers";

const GET_CUSTOMER_INFO = `${CUSTOMERS_SERVICE}/`;
const UPDATE_CUSTOMER_INFO = `/users/profile`;
const GET_CUSTOMER_FILES = `${CUSTOMERS_SERVICE}/files`;
// const GET_CUSTOMER_MESSAGES = `/messages`;
const GET_CUSTOMER_MESSAGES = `${CUSTOMERS_SERVICE}/messages`;
const GET_CUSTOMER_TASKS = `${CUSTOMERS_SERVICE}/tasks`;
const GET_CUSTOMER_VIDEOS = `${CUSTOMERS_SERVICE}/videos`;
const GET_CUSTOMER_HINTS = `/hints`;      
const VALIDATE_USER = `/users/me`;

const customer = {
  GET_CUSTOMER_FILES,
  GET_CUSTOMER_MESSAGES,
  GET_CUSTOMER_TASKS,
  GET_CUSTOMER_VIDEOS,
  GET_CUSTOMER_HINTS,
  GET_CUSTOMER_INFO,
  UPDATE_CUSTOMER_INFO,
  VALIDATE_USER
};

export default customer;

import * as ACTIONS from './action_types';
import api from '../../api';
import axios from '../../utils/axios/axios';
import { fetchGates } from '../menuReducer/actions';
import { showLoader, hideLoader } from '../../Components/Loader/Loader.js';
import { AUTH_URL } from '../../utils/env';

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

// export const AUTH_URL = 'https://tegunlocked.com/';
export { AUTH_URL };

export const setNewToken = (token) => {
  return (dispatch) => {
    dispatch(setTokenInCookies(token));
    dispatch({
      type: ACTIONS.CURRENT_TOKEN,
      payload: token,
    });
  };
};

export const fetchUser = () => {
  const url = api.customer.GET_CUSTOMER_INFO;
  return (dispatch) => {
    dispatch(setProcessing(true));
    axios
      .get(url)
      .then(({ status, data }) => {
        const { user } = data;
        dispatch({
          type: ACTIONS.CURRENT_USER,
          payload: user,
        });
        dispatch({
          type: ACTIONS.AUTH_STATUS,
          payload: true,
        });
        dispatch(setProcessing(false));
        dispatch(fetchGates());
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: ACTIONS.AUTH_STATUS,
          payload: false,
        });
        dispatch(setProcessing(false));
      });
  };
};
export const validateUser = () => {
  const url = api.customer.VALIDATE_USER;
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: ACTIONS.USER_VALID,
          payload: true,
        });
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.USER_VALID,
          payload: false,
        });
      });
  };
};
export const updateUser = (prof) => {
  const url = api.customer.UPDATE_CUSTOMER_INFO;
  return (dispatch) => {
    // dispatch(addLoader());
    axios
      .put(url, prof)
      .then(() => {
        dispatch(fetchUser());
      })
      .catch((error) => {
        console.log(error);
        // dispatch(hideLoader());
      });
  };
};

export const isAuthenticated = (getState) => {
  return getState().authReducer.user.id !== undefined;
};

export const getTokenFromCookies = () => {
  let tokenName = 'token=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(tokenName) == 0) {
      return c.substring(tokenName.length, c.length);
    }
  }
  return '';
};

export const setTokenInCookies = (newToken) => {
  let oldCookie = document.cookie.toString();
  let tokenName = 'token=';
  if (oldCookie.includes(tokenName)) {
    document.cookie = `${tokenName}${newToken}`;
  } else {
    document.cookie = `${tokenName}${newToken}`;
  }
  return {
    type: ACTIONS.CURRENT_TOKEN,
    payload: '',
  };
};
import * as ACTIONS from "./action_types";

const initialState = {
  fetching: false,
  success: false,
  failure: false,
  gates: [],
  isGameComplete: false
};
 
const menuReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PROCESSING:
      return {
        ...state,
        fetching: payload,
      };

    case ACTIONS.FETCH_GATES:
      return {
        ...state,
        fetching: false,
        success: true,
        gates: payload,
      };
    
    case ACTIONS.GAME_COMPLETE:
      return {
        ...state,
        isGameComplete: true
      };
    case ACTIONS.GAME_INCOMPLETE:
      return {
        ...state,
        isGameComplete: false
      };

    default:
      return {
        ...state,
      };
  }
};

export default menuReducer;

import React from 'react';
import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';


const GateButton = ({
  isSelected,
  icon,
  iconMobile,
  text,
  isCompleted,
  isLocked,
  onClick,
  img,
  imgSelected
}) => {
  const [play] = useSound(sfx.AUDIO_HOVER);
  const [playClick] = useSound(sfx.AUDIO_ACTION_CLICK);
  img=isSelected?imgSelected:img 
  let classes = 'btn gate-btn btn-black-gradient';
  if (isSelected && !isLocked) classes = classes.concat(' gate-btn-selected');
  isCompleted
    ? (classes = classes.concat(' gate-btn-complete'))
    : isLocked
    ? (classes = classes.concat(' gate-btn-close'))
    : (classes = classes.concat(' gate-btn-open'));

  return (
    <div
      className={classes}
      onClick={() => {
        if (!isLocked) {
          playClick();
          onClick();
        }
      }}
      onMouseEnter={() => play()}
    >
      <span className='btn-logo-box'>
        <img src={img && img} alt='btn-logo' className='btn-logo' />
      </span>
      <span className='btn-text'>{text}</span>
        {isLocked?<span className="lock-icon">
        <img className="lock-icon-desktop" src={icon} alt="Lock Icon"/>
        {/* <img className="lock-icon-mobile" src={iconMobile} alt="Lock Icon"/> */}
      </span>:''}
      
    </div>
  );
};

GateButton.propTypes = {};

export default GateButton;

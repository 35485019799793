import React, { useEffect } from "react";
import videoIcon from "../../assets/images/Video_Thumbnail.png"
import "./index.css";

const VideoIcon = ({ title, iconImg, isLocked, onClick, selectedVideo, thumbnail }) => {
  useEffect(() => {

  }, []);
  const iconClass = selectedVideo.url !== undefined ? "video-thumbnail blurred-icon" : "video-thumbnail"
  return (
    <div className='file-icon' onClick={onClick}>
        <img alt="Video Thumbnail" className={iconClass} src={videoIcon} />
        <img alt="Thumbnail" className="thumbnail" src={thumbnail} /> 
        {/* <img alt="Play Icon" className="video-icon" src={iconImg} /> */}
        <div className="file-title">{title}</div>
    </div>
  );
};

VideoIcon.propTypes = {
};

export default VideoIcon;

import React from 'react';
import './index.css';

import rightArrowIcon from '../../assets/images/svg/arrow.svg';

const HintTasks = ({ hints, selectedGate, selectedHint, setSelectedHint, hintContentHtml }) => {
    // console.log(selectedGate);
    // console.log(selectedHint);
    return (
        <div className="" style={selectedGate.title === undefined ? { display: 'none' } : {}}>
            <div className="hints-list-container">
                <div className="hints-list-body">
                    <ul className="hints-list-ul">
                        {hints && hints.length > 0 ? hints.map((hint, index) => {
                            const classes = selectedHint.title === hint.title ? "item-selected" : "";
                            return <React.Fragment key={index}> <li onClick={() => { selectedHint.title === hint.title ? setSelectedHint({}) : setSelectedHint({ index: index, ...hint }) }} className={classes}>
                                <div>{hint.title}</div>
                                <div><img alt="Right Arrow" className="hints-list-ul-icon" src={rightArrowIcon} /></div>
                            </li>
                            {selectedHint.title === hint.title ? <div className="d-d-none">{hintContentHtml} </div> : ''}
                            </React.Fragment>;
                        }) : ''}
                    </ul>
                </div>
            </div>
        </div>
    );
};

HintTasks.propTypes = {};

export default HintTasks;
import React,{useEffect} from "react";
import PropTypes from "prop-types";
import Button from "../../Components/Button/Button";
import filesLogo from "../../assets/images/svg/file-logo-white.svg";
import filesLogoSelected from "../../assets/images/svg/files-selected.svg";
import messagesLogo from "../../assets/images/svg/message.svg";
import messagesLogoSelected from "../../assets/images/svg/message-selected.svg";
import videosLogo from "../../assets/images/svg/video.svg"; 
import videosLogoSelected from "../../assets/images/svg/video-selected.svg"; 
import HintLogo from "../../assets/images/svg/hint.svg"; 
import HintLogoSelected from "../../assets/images/svg/hint-selected.svg"; 
import settingLogo from "../../assets/images/svg/setting.svg"; 
import settingLogoSelected from "../../assets/images/svg/setting-selected.svg"; 

import FieldSelectedLogo from "../../assets/images/svg/field-selected.svg"; 
import FieldLogo from "../../assets/images/svg/field.svg"; 
import GatesMenu from "../../Components/GatesMenu/GatesMenu"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchGates } from "../../store/menuReducer/actions";
import { loadGate } from "../../store/gateReducer/action";

import "./index.css";

const BTN_TEXT_FILES = "files";
const BTN_TEXT_MESSAGES = "messages";
const BTN_TEXT_VIDEOS = "videos";
const BTN_TEXT_FIELD = "field";
const BTN_TEXT_HINTS = "hints";
const BTN_TEXT_SETTINGS = "settings";

const Four9ErsMenu = ({ history,loadNewGate,selectedGateId,getGates,location: { pathname }, totalUnreadMsgs, totalUnreadFiles }) => {
  useEffect(() => {
    getGates();

  }, []);
  const redirect = (path) => {
    history.push(path);
  };
  
  const isSelected = (selectedTitle = '') => {  
    return pathname.includes(selectedTitle.toLowerCase());
  };
  const isGateSelect = (gateId) => pathname === "/games" && selectedGateId === gateId;

  return (
    <div className="menu-container dahsbord-side-nav">
 
        <Button
          // icon={filesLogoSelected}
          redButton={true}
          icon={isSelected(BTN_TEXT_FILES) ? filesLogoSelected : filesLogo}
          text={BTN_TEXT_FILES}
          isSelected={isSelected(BTN_TEXT_FILES)}
          onClick={() => {
            redirect(`/${BTN_TEXT_FILES}`, BTN_TEXT_FILES);
          }}
          notificationCount={totalUnreadFiles}
        />
        <Button
          redButton={true}
          icon={isSelected(BTN_TEXT_MESSAGES)? messagesLogoSelected:messagesLogo}
          text={BTN_TEXT_MESSAGES}
          isSelected={isSelected(BTN_TEXT_MESSAGES)}
          onClick={() => {
            redirect(`/${BTN_TEXT_MESSAGES}`, BTN_TEXT_MESSAGES);
          }}
          notificationCount={totalUnreadMsgs}
        />
        <Button
          redButton={true}
          icon={isSelected(BTN_TEXT_VIDEOS)? videosLogoSelected:videosLogo}
          text={BTN_TEXT_VIDEOS}
          isSelected={isSelected(BTN_TEXT_VIDEOS)}
          onClick={() => {
            redirect(`/${BTN_TEXT_VIDEOS}`, BTN_TEXT_VIDEOS);
          }}
        />
    
        <Button
          redButton={false}
          icon={isGateSelect(0)? FieldSelectedLogo:FieldLogo}
          text={BTN_TEXT_FIELD}
          isSelected={isGateSelect(0)}
          onClick={() => {
            if (pathname !== "/games") redirect("/games");
            loadNewGate("Field", 0);
          }}
        />
          <GatesMenu pathname={pathname} redirect={redirect}/>
    
      
        <Button
          redButton={true}
          icon={isSelected(BTN_TEXT_HINTS)?HintLogoSelected: HintLogo}
          text={BTN_TEXT_HINTS}
          isSelected={isSelected(BTN_TEXT_HINTS)}
          onClick={() => {
            redirect(`/${BTN_TEXT_HINTS}`, BTN_TEXT_HINTS);
          }}
        />
        <Button
           redButton={true}
          icon={isSelected(BTN_TEXT_SETTINGS)?settingLogoSelected: settingLogo}
          text={BTN_TEXT_SETTINGS}
          isSelected={isSelected(BTN_TEXT_SETTINGS)}
          onClick={() => {
            redirect(`/${BTN_TEXT_SETTINGS}`, BTN_TEXT_SETTINGS);
          }}
        />

    </div>
  );
};
Four9ErsMenu.propTypes = {
  loadNewGate: PropTypes.func,
  selectedGateTitle: PropTypes.string,
};

const mapStateToProps = ({ gateReducer, messagesReducer, filesReducer }) => ({
  selectedGateTitle: gateReducer.gateTitle,
  totalUnreadMsgs: messagesReducer.totalUnread,
  totalUnreadFiles: filesReducer.totalUnread,
  selectedGateId: gateReducer.gateId

});

const mapDispatchToProps = {
  getGates: () => fetchGates(),
  loadNewGate: (gateTitle, gateId) => loadGate(gateTitle, gateId),

};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Four9ErsMenu)
)
import React from 'react';
import './index.css';

// import gatesListIcon from '../../assets/images/folder_icon.png';
const HintContent = ({ selectedGate, selectedHint }) => {
  const getContent = () => {
    let formattedContent = selectedHint && selectedHint.content
    const EMAIL = 'support@49ers';
    if (selectedHint?.content?.includes(EMAIL)) {
      return <React.Fragment>Check the hint guide to make sure your dashboard is not working properly. Then email <a href={`mailto:${EMAIL}`}>{EMAIL}</a> with any issues you may have.</React.Fragment>;
    }
    return <React.Fragment>
      <span>{formattedContent}</span>
      <span>
        {selectedHint.image ? <img className='hints-contents-body-image' src={selectedHint.image} alt=""/>: ''}
      </span>
    </React.Fragment>;
  }
  return (
    <div className="" style={selectedHint.title === undefined ? { display: 'none' } : {}}>
      <div className="hints-content-container">
        <div className="hints-content-body">
          {getContent()}
        </div>
      </div>
    </div>
  );
};

HintContent.propTypes = {};

export default HintContent;
import React from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import Four9ErsRout from "../Four9ErsRoute/Four9ErsRout";
import Files from "../../Components/Files/Files"
import Hints from "../../Components/Hints/Hints";
import Message from "../../Components/Messages/Messages";
import FileFullView from '../../Components/Files/FileFullView';
import Login from '../../Components/Login/Login';
import Videos from '../../Components/Videos/Videos';
import Settings from '../../Components/Settings/Settings';
import ChangePassword from '../../Components/ChangePassword/ChangePassword';
import ForgotPassword from '../../Components/ForgotPassword/ForgotPassword';
import ResetPassword from '../../Components/ResetPassword/ResetPassword';

import Verify from '../../Components/Verify/Verify';

const Four9ErsGame = () => {
  return (
    <Router>
      <Switch>
        <Four9ErsRout exact path="/games" component={() => <div></div>} />
        <Four9ErsRout exact path="/files" component={Files} />
        <Four9ErsRout exact path="/messages" component={Message} />
        <Four9ErsRout exact path="/videos" component={Videos} />
        <Four9ErsRout exact path="/hints" component={Hints} />
        <Four9ErsRout exact path="/settings" component={Settings} />
        <Four9ErsRout exact path="/change-password" component={ChangePassword} />
        <Four9ErsRout exact path="/fullview/:fileid" component={FileFullView} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/forgot-password/:token" component={ResetPassword} />

        <Route exact path="/" component={Verify} />
        <Redirect to="/games" />
      </Switch>
    </Router>
  );
};

Four9ErsGame.propTypes = {};

export default Four9ErsGame;

import * as ACTIONS from './action_types';
import api from '../../api';
import axios from '../../utils/axios/axios';

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseVideo = ({
  isWatched,
  video: { id, title, thumbnail, url, gameId, taskId },
}) => ({
  isWatched,
  id,
  title,
  thumbnail,
  url,
  gameId,
  taskId,
});

const parseVideos = (videos) =>videos && videos.length > 0 && videos.map((video) => parseVideo(video));

const getUnwatchedVideo = (videos) => {
  for (let i = 0; i < videos.length; ++i) {
    if (!videos[i].isWatched) {
      return { ...videos[i] };
    }
  }

  return null;
};

export const fetchVideos = () => {
  const url = api.customer.GET_CUSTOMER_VIDEOS;
  return (dispatch) => {
    dispatch(setProcessing(true));
    axios.get(url).then(({ status, data }) => {
      const { videos } = data;
      // const customerVideos = null;
      // const unwatchedVideo = null;
      const customerVideos = parseVideos(videos);
      const unwatchedVideo = getUnwatchedVideo(customerVideos);
      dispatch({
        type: ACTIONS.FETCH_VIDEOS,
        payload: { customerVideos, unwatchedVideo },
      });
    });
  };
};

export const markVideoAsWatched = (videoId) => {
  const url = `${api.videos.MARK_VIDEO_AS_WATCHED}/${videoId}`;

  return (dispatch) => {
    dispatch(setProcessing(true));
    axios
      .put(url)
      .then(({ status, data }) => {
        dispatch(setProcessing(false));
        dispatch(fetchVideos());
      })
      .catch((err) => console.log(err));
  };
};

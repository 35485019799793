const AUTH = '/users';

const USER_LOGIN = `${AUTH}/login`;
const USER_SIGNUP = `${AUTH}/signup`;
const USER_ACTIVATE_CODE = `${AUTH}/activate-code`;

const auth = {
    USER_LOGIN,
    USER_SIGNUP,
    USER_ACTIVATE_CODE
};

export default auth;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from "../../Components/Header/Header";
import VideoModal from '../../Components/Videos/VideoModal';
import CongratsScreen from '../../Components/Files/CongratsScreen';
import Four9ErsMenu from "../Four9ErsMenu/Four9ErsMenu";
import { fetchFiles } from '../../store/filesReducer/action';
import { fetchMessages } from '../../store/messagesReducer/actions';
import { fetchVideos } from '../../store/videosReducer/actions';
import statusTrophy from "../../assets/images/Status_Trophy.png"
import statusTrophyComplet from "../../assets/images/Status_Trophy_Obtained.png"
import "./index.css";

const Four9ErsDashboard = ({ getVideos,
  gates,
  getMessages,
  getFiles,
  children,
  introVideo,
  isGameComplete }) => {

    const [hideVideo, setVideoHidden] = useState(false);
  useEffect(() => {
    getVideos();
    getMessages();
    getFiles();
  }, []);

  useEffect(() => {
    if(introVideo && introVideo.id === 3) {
      setVideoHidden(true);
    }
  }, [introVideo]);

  useEffect(() => {
    if(hideVideo) {
      setTimeout(()=> {
        setVideoHidden(false);
      }, 6000);
    }
  }, [hideVideo]);

  const [trophyCount, setTrophyCount] = useState()
  useEffect(() => {
    let _trophyCount=0;
    gates && gates.forEach(item => {
      if (item.status === "complete" && item.gameId === 2 ) {
        _trophyCount += 1;
      }
      if (item.status === "complete" && item.gameId === 3) {
        _trophyCount += 2;
      }
      if (item.status === "complete" && item.gameId === 4) {
        _trophyCount += 2;
      }
    });
    setTrophyCount(_trophyCount)
  }, [gates])
  return (
    <div className='four-9-dashboard'>
      <Header />
      <div className='content-box'>
        <div className='four-9-dashboard-menu-area'>
          <Four9ErsMenu />
        </div>
        <div className='four-9-dashboard-content-area'>{children}</div>
      </div>
      <div className='four-9-dashboard-contener-area-trophy'>
        <div className='four-9-dashboard-content-trophy'>
          <img
            src={trophyCount >= 1 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 2 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 3 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 4 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 5 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
        </div>
      </div>
      <div className='four-9-dashboard-contener-responsive-trophy'>
        <div className='four-9-dashboard-content-responsive-trophy'>
          <img
            src={trophyCount >= 1 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 2 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 3 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 4 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
          <img
            src={trophyCount >= 5 ? statusTrophyComplet : statusTrophy}
            alt='statusTrophy'
          />
        </div>
      </div>
      {introVideo && !hideVideo && introVideo.id !== 4 ? (
        <div className='video-content-box'>
          <VideoModal
            videoId={introVideo.id}
            title={introVideo.title}
            url={introVideo.url}
          />
        </div>
      ) : null}

      {isGameComplete && introVideo ? (
        <div className='video-content-box'>
          <VideoModal
            videoId={introVideo.id}
            title={introVideo.title}
            url={introVideo.url}
          />
        </div>
      ) : isGameComplete ? <CongratsScreen /> : null}

      {/* {isGameComplete && !introVideo ? <CongratsScreen /> : null} */}
    </div>
  );
}

Four9ErsDashboard.propTypes = {
  getFiles: PropTypes.func,
  getVideos: PropTypes.func,
  getMessages: PropTypes.func,
  introVideo: PropTypes.oneOf([null, PropTypes.shape({})]),
  gates: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.oneOf([null, PropTypes.object]),
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      gameId: PropTypes.number,
      taskId: PropTypes.number,
      customerId: PropTypes.number,
      isLocked: PropTypes.bool,
      isComplete: PropTypes.bool,
    })
  ).isRequired,
};
Four9ErsDashboard.defaultProps = {
  gates: [],
};


const mapStateToProps = ({ videosReducer, loadingReducer, menuReducer }) => ({
  gates: menuReducer.gates,
  introVideo: videosReducer.unwatchedVideo,
  isLoading: loadingReducer.isLoading,
  isGameComplete: menuReducer.isGameComplete
});

const mapDispatchToProsp = {
  getVideos: () => fetchVideos(),
  getMessages: () => fetchMessages(),
  getFiles: () => fetchFiles()
};

export default connect(mapStateToProps, mapDispatchToProsp)(Four9ErsDashboard);
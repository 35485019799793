import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import gateReducer from "./gateReducer/reducer";
import filesReducer from "./filesReducer/reducer";
import menuReducer from "./menuReducer/reducer";
import hintsReducer from "./hintsReducer/reducer";
import messagesReducer from "./messagesReducer/reducer";
import loadingReducer from "./loadingReducer/reducer";
import authReducer from "./authReducer/reducer";
import videosReducer from "./videosReducer/reducer";



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore( 
  combineReducers({
    filesReducer,
    gateReducer,
    menuReducer,
    hintsReducer,
    messagesReducer,
    loadingReducer,
    authReducer,
    videosReducer,
 
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;

import {ASSETS_URL} from './env';

const AUDIO_HOVER = `${ASSETS_URL}/Audio/Hover.wav`;
const AUDIO_POPUP_OPEN = `${ASSETS_URL}/Audio/AUDIO_ACTION_CLICK.wav`;
const AUDIO_POPUP_CLOSE = `${ASSETS_URL}/Audio/AUDIO_POPUP_CLOSE.wav`;
const AUDIO_NEW_MESSAGE = `https://unlocked3.s3.us-east-2.amazonaws.com/sfx/BELLS_Simple%26Nice_01.wav`;
const AUDIO_NEW_FILE = `https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Bonus+Point.wav`;
const AUDIO_INVENTORY_CONTROL = `https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Wooshes8.wav`;
const AUDIO_ACTION_CLICK = `${ASSETS_URL}/Audio/AUDIO_ACTION_CLICK.wav`;
const AUDIO_GAME_WIN = `${ASSETS_URL}/Audio/Win.wav`;

export default {
    AUDIO_HOVER,
    AUDIO_POPUP_OPEN,
    AUDIO_POPUP_CLOSE,
    AUDIO_NEW_MESSAGE,
    AUDIO_NEW_FILE,
    AUDIO_INVENTORY_CONTROL,
    AUDIO_ACTION_CLICK,
    AUDIO_GAME_WIN
}
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.css';
import Popup from '../Popup/Popup';

import HintGatesList from './HintGatesList';
import HintsList from './HintsList';
import HintContent from './HintContent';
import HintTasks from './HintTasks';

import { fetchHints } from '../../store/hintsReducer/actions';

const Hints = ({ hints, history, getHints, gates, gatesTasks }) => {
  const [totalHints] = useState({});
  useEffect(() => {
    getHints();
  }, [totalHints]);

  const [selectedGate, setSelectedGate] = useState({});
  const [selectedHint, setSelectedHint] = useState({});
  const [selectedHintTasks, setSelectedHintTasks] = useState({});
  const onClosePopup = e => {
    history.goBack();
  }

  const changeGate = (gate) => {
    if (gate.title === undefined) {
      setSelectedGate({});
      setSelectedHint({});
    } else {
      setSelectedGate(gate);
      if(gate.index === 5) {
        setSelectedHint({
          index: 0,
          ...hints['FAQ'][0]
        })
      } else {
        setSelectedHint({});
      }
    }
  }

  useEffect(() => {
    setSelectedHintTasks({});
  }, [selectedHint]);

  const getHintContentHtml = <HintContent selectedGate={selectedGate} selectedHint={selectedHintTasks} />;
  const getHintsListHtml = <HintsList hints={selectedGate.title ? hints[selectedGate.title] : {}} selectedGate={selectedHint} selectedHintTask={selectedHintTasks} selectedHint={selectedHint} setSelectedHint={setSelectedHintTasks} hintContentHtml={getHintContentHtml} />
  const getHintsTasksListHtml = <HintTasks hints={selectedGate.title ? hints[selectedGate.title] : {}} selectedGate={selectedGate} selectedHint={selectedHint} setSelectedHint={setSelectedHint} hintContentHtml={getHintsListHtml} />

  return (
    <div className="hints-container">
      <Popup title="Hints" headerBG="#e65778" fontColor="#fff" >
        <div className="hints-section">
          <HintGatesList selectedGate={selectedGate} setSelectedGate={changeGate} gates={gates} hints={hints} selectedHint={selectedHint} hintsHtml={getHintsTasksListHtml} />
          <div className="d-m-none hints-list" style={{ display:  selectedHint.hintId === 6 ? 'none': 'block' }}>{getHintsTasksListHtml}</div>
          <div className="d-m-none hints-list-section" style={{ marginLeft:  selectedHint.hintId === 6 ? '26px': '0px' }}>{getHintsListHtml}</div>
          {/* <div className="d-m-none hints-content">{getHintContentHtml}</div> */}
        </div>
      </Popup>
    </div>
  );
};

Hints.propTypes = {
};

const mapStateToProps = ({ hintsReducer }) => ({
  hints: hintsReducer.hints,
  gates: hintsReducer.gates,
  gatesTasks: hintsReducer.gatesTasks

});

const mapDispatchToProps = {
  getHints: () => fetchHints(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Hints);
import * as ACTIONS from "./action_types";
import api from "../../api";
import axios from "../../utils/axios/axios";
import sfx from '../../utils/sfx';

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseMessage = ({
  isRead,
//  id, title, subject, message, gameId, taskId, type ,
  message: { id, title, subject, message, gameId, taskId, type },
}) => ({
  isRead,
  id,
  title,
  subject,
  message,
  gameId,
  taskId,
  type,
  isPopUp: type === "popup",
});

const parseMessages = (messages) =>
  messages.map((message) => parseMessage(message));

export const fetchMessages = () => {
  const url = api.customer.GET_CUSTOMER_MESSAGES;
  return (dispatch, getState) => {
    dispatch(setProcessing(true));
    const oldUnreadMsgsCount = getState().messagesReducer.totalUnread;
    axios.get(url).then(({ status, data }) => {
      // const { messages, total_unread } = data;
      const { customerMessages, total_unread } = data;
      const messages = parseMessages(customerMessages);
      dispatch({
        type: ACTIONS.FETCH_MESSAGES,
        payload: {messages, totalUnread: total_unread},
      });
      if(total_unread > oldUnreadMsgsCount) {
        playNewMsgSound();
      }
    });
  };
};


export const markMessageAsRead = (msgId) => {
  const url = `${api.messages.MARK_MESSAGE_AS_READ}/${msgId}`;

  return (dispatch) => {
    dispatch(setProcessing(true));
    axios
      .put(url)
      .then(({ status, data }) => {
        dispatch(setProcessing(false));
        dispatch(fetchMessages());
      })
      .catch((err) => console.log(err));
  };
};

const playNewMsgSound = () => {
  const audio = new Audio(sfx.AUDIO_NEW_MESSAGE)
  audio.play()
}
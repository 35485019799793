import React, { useEffect } from 'react';
import './index.css';

import msgReadIcon from '../../assets/images/svg/message-open.svg';
import msgUnreadIcon from '../../assets/images/svg/message-golden.svg';
import useSound from "use-sound";
import sfx from "../../utils/sfx";

const MessagesList = ({ messages, selectedIndex, onSelect }) => {
  useEffect(() => { }, []);
  const [playPopupOpen] = useSound(sfx.AUDIO_POPUP_OPEN);

  return messages.map((msg, index) => {
    let itemTextClass=''
    let itemClass =
      index === selectedIndex ? 'msg-list-item selected-item' : 'msg-list-item';
    const msgIcon = msg.isRead ? msgReadIcon : msgUnreadIcon;
    msg.isRead ? itemTextClass =itemTextClass.concat( 'selected-text '): itemTextClass =itemTextClass.concat('unselected-text msg-list-item-hover')
    return (
      <div key={index} className="message-box" onClick={() => playPopupOpen()}>
        <div onClick={() => onSelect(index, msg)} className={itemClass}>
          <div className="msg-subject">
            <div className="msg-options">
              <div className="msg-status">
                <img src={msgIcon} alt="Read Message" />
              </div>
            </div>
            <div className={itemTextClass}>{`SUBJECT: ${msg.subject}`}</div>
          </div>
        </div>

        {index === selectedIndex ? (
          <div className="collapsed-msg-content">{msg.message}</div>
        ) : (
            ''
          )}
      </div>
    );
  });
};

MessagesList.propTypes = {};

export default MessagesList;

const environment = process.env.REACT_APP_DEPLOYMENT;

let ASSETS_BASE, SERVER_BASE, AUTH_BASE, CONSTRUCT_BASE;

if (environment === 'PRODUCTION') {
  ASSETS_BASE = 'https://games.49ers.com/static-assets';
  SERVER_BASE = 'https://games-api.49ers.com';
  AUTH_BASE = 'https://games.49ers.com';
  CONSTRUCT_BASE = 'https://games.49ers.com/construct/index.html';
} else if (environment === 'LOCAL') {
  ASSETS_BASE = 'https://d1hk5wzgq7iuf1.cloudfront.net';
  SERVER_BASE = 'http://localhost:8000';
  AUTH_BASE = 'http://d2ueyiupq1jdn7.cloudfront.net';
  CONSTRUCT_BASE = 'http://49ers-construct.s3-website.us-east-2.amazonaws.com';
} else {
  ASSETS_BASE = 'https://d1hk5wzgq7iuf1.cloudfront.net';
  SERVER_BASE = 'http://3.132.41.220:8000';
  AUTH_BASE = 'http://d2ueyiupq1jdn7.cloudfront.net';
  CONSTRUCT_BASE = 'http://49ers-construct.s3-website.us-east-2.amazonaws.com';
}

export const ASSETS_URL = `${ASSETS_BASE}`;
export const SERVER_URL = `${SERVER_BASE}/api/v1`;
export const AUTH_URL = `${AUTH_BASE}`;
export const SERVER_BASE_URL = `${SERVER_BASE}`;
export const CONSTRUCT_URL = `${CONSTRUCT_BASE}`;

import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useSwipeable } from "react-swipeable";

import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';
import {getTokenFromCookies} from '../../store/authReducer/actions';
import Popup from '../Popup/Popup';

import {downloadFile} from '../../store/filesReducer/action';

import {Link} from 'react-router-dom';

const FilesPopup = ({ file, onClose, getToken }) => {
  const [play] = useSound(sfx.AUDIO_ACTION_CLICK);
  const [playWoosh] = useSound(sfx.AUDIO_INVENTORY_CONTROL);
  const [pageNum, setPageNum] = useState(0); 

  useEffect(() => {

  }, []);

  useEffect(() => {
    playWoosh();
  }, [pageNum]);

  const handlers = useSwipeable({
    onSwipedLeft: () => setPageNum(Math.min(pageNum+1, 6)),
    onSwipedRight: () => setPageNum(Math.max(pageNum-1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const fileContent = (fileData) => {
    if(file.url !== '' && !file.url.includes(".zip") && !file.url.includes(".pdf")  ){
        return <img alt="File Content" src={file.url} />;
    }
    let pages = {};
    try {
        pages = JSON.parse(fileData.pages);
    } catch (err) {
        pages = fileData.pages;
    }

    return (
    <span>
        <img alt="File Content" src={pages[pageNum]} /> 
        <div className="image-controls-container">
            <div className="image-controls">
                <div onClick={() => setPageNum(Math.max(pageNum-1, 0))} className={pageNum===0 ? "next-img faded-img" : "next-img"}>
                    {/* <img alt="Previous" src={prevImgIcon} /> */}
                    <span style={pageNum===0? {opacity: 0.1} : {}} className="text">Previous</span>
                </div>
                <div onClick={() => setPageNum(Math.min(pageNum+1, pages.length-1))} className={pageNum===pages.length-1 ? "prev-img faded-img" : "prev-img"}>
                    {/* <img alt="Next" src={nextImgIcon} /> */}
                    <span style={pageNum===file.pages.length-1? {opacity: 0.1} : {}} className="text">Next</span>
                </div>
            </div>
        </div>
    </span>);
  }

  let LOCALHOST_SERVER_URL='http://localhost:8000/api/v1'
  // const SERVER_URL  = "http://3.132.41.220:8000/api/v1";
  const onBackDrop = (e) => {
    e.stopPropagation()
    onClose();
  }
  const stopProg = (e) => {
    e.stopPropagation();
  }
  return (
    <div className="file-popup" onClick={(e) => onBackDrop(e)}>
        <Popup  headerBG="#323b92" fontColor="#fff" onEventClose={onClose}>
            <div className="filecontent-container"  {...handlers}  onClick={(e) => stopProg(e)}>
              {fileContent(file)}
            <div className="file-btns">
               <Link to={`fullview/${file.fileId}`} className="btn gate-btn btn-primary">Full View</Link>
                {/* <a className="btn gate-btn btn-primary" href={file.url.includes(".zip") ? `fullview/${file.fileId}` : file.url} target="_blank" onClick={() => play()}>Full view</a> */}
                <a className="btn gate-btn btn-primary" target={`_blank`} href={`${file.url}`} download onClick={() => play()}>Download</a>
                {/* <a className="btn gate-btn btn-primary" href={`${SERVER_URL}/files/download/${file.fileId}?token=${getCookie}`} download onClick={() => play()}>Download</a> */}
            </div>
            </div>
        </Popup>
    </div>
  );
};

FilesPopup.propTypes = {
};

const mapStateToProps = ({  }) => ({ });
  
  const mapDispatchToProps = {
    downloadFileById: (id) => downloadFile(id),
    getToken: () => getTokenFromCookies()
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(FilesPopup);
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchGates } from "../../store/menuReducer/actions"
import GateButton from "../Button/GateButton";
import { showLoader, hideLoader } from "../../Components/Loader/Loader.js";

import IconImg1 from "../../assets/images/svg/security.svg";
import IconImg11 from "../../assets/images/svg/security-selected.svg";
import IconImg2 from "../../assets/images/svg/playbook.svg";
import IconImg22 from "../../assets/images/svg/playbook-selected.svg";
import IconImg3 from "../../assets/images/svg/lookerRoom.svg";
import IconImg33 from "../../assets/images/svg/lookerRoom-selected.svg";
import IconImg4 from "../../assets/images/svg/museum.svg";
import IconImg44 from "../../assets/images/svg/museum-selected.svg";
import IconImg5 from "../../assets/images/svg/trophy.svg";
import IconImg55 from "../../assets/images/svg/trophy-selected.svg";


import LockIcon from "../../assets/images/svg/lock.svg";
import LockIconMobile from "../../assets/images/icon/Menu_Lock_mobile.png";
import { loadGate } from "../../store/gateReducer/action";

const GatesMenu = ({ gates, pathname, redirect, loadNewGate, selectedGateId }) => {
  const isGateSelect = (gateId) => pathname === "/games" && selectedGateId === gateId;
  return (
    <>
      {gates.map((task, index) => {
        task.image=(task.title==="Security office")?IconImg1:(task.title==="Playbook")?IconImg2:(task.title==="Locker room")?IconImg3:(task.title==="Museum")?IconImg4:IconImg5
        task.imgSelected=(task.title==="Security office")?IconImg11:(task.title==="Playbook")?IconImg22:(task.title==="Locker room")?IconImg33:(task.title==="Museum")?IconImg44:IconImg55
        return (
          <GateButton
            key={index}
            img={task.image}
            imgSelected={task.imgSelected}
            icon={LockIcon}
            iconMobile={task.isLocked ? LockIconMobile : LockIconMobile}
            isSelected={isGateSelect(task.gameId)}
            text={task.title}
            onClick={() => {
              // showLoader()
              if (pathname !== "/games") redirect("/games");
              loadNewGate(task.title, task.gameId);
            }}
          isCompleted={task.isComplete}
          isLocked={task.isLocked}
          />
        )
      })}
    </>
  );
};

GatesMenu.defaultProps = {
  gates: [],
};

GatesMenu.propTypes = {
  gates: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.oneOf([null, PropTypes.object]),
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      gameId: PropTypes.number,
      taskId: PropTypes.number,
      customerId: PropTypes.number,
      isLocked: PropTypes.bool,
      isComplete: PropTypes.bool,
    })
  ).isRequired,
};

const mapStateToProps = ({ menuReducer, gateReducer }) => ({
  gates: menuReducer.gates,
  loadNewGate: (gateTitle, gateId) => loadGate(gateTitle, gateId),
  selectedGateId: gateReducer.gateId
});

const mapDispatchToProps = {
  loadNewGate: loadGate
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesMenu);

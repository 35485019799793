import React, { useState, useEffect } from 'react';
import axios, { post } from 'axios';
import { connect } from 'react-redux';
import './index.css';
import useSound from 'use-sound';
import sfx from '../../utils/sfx';
import Popup from '../Popup/Popup';
import { resetGame } from '../../store/gateReducer/action';
import { setNewToken, updateUser, AUTH_URL } from '../../store/authReducer/actions';
import CameraIcon from '../../assets/images/Camera_Icon.png'
const ChangePassword = ({ history, doResetGame, currentUser, doAbandonGame, doUpdateProfile }) => {
    const [play] = useSound(sfx.AUDIO_ACTION_CLICK);
    const [formData, setFormData] = useState({});
    const [msg, setMsg] = useState('');
    useEffect(() => {
        setFormData({
            password: '',
            confirmPassword: ''
        });
    }, []);

    const onFormChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    const onEventUpdateProfile = e => {
        e.preventDefault();
        setMsg('');
        if(formData.password.length < 6) {
            setMsg('Password should be of min 6 length');
        } else if (formData.password !== formData.confirmPassword) {
            setMsg('Password and confirm password do not match');
        } else {
            setMsg('Please wait...');
            doUpdateProfile(formData);
            setTimeout(() => {
                setMsg('Password changed successfully');
            }, 3000);
        }
    }

    return (
        <div className="files-container settings-container">
            <Popup title="Profile | Change Password" headerBG="#000" fontColor="#fff">
                <div className="setting-main-panel">
                            <form onChange={onFormChange} >
                                <div className="setting-panel">
                                    <div className="setting-left-panel change-password-form">
                                        <div className="form-group">
                                            <div className="form-1 w-100">
                                                <div className="errorDiv">
                                                    <label>Password</label>
                                                </div>
                                                <input type="password" name="password" defaultValue={formData.password} disabled={false} placeholder="Password" autoComplete="off" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-1 w-100">
                                                <div className="errorDiv">
                                                    <label>Confirm Password</label>
                                                </div>
                                                <input type="password" name="confirmPassword" defaultValue={formData.confirmPassword} placeholder="Confirm Password" autoComplete="off" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="save-changes-btn">
                                    <h4 className='password-message'>{msg}</h4>
                                    <input onClick={onEventUpdateProfile} className="saveChangesButton" type="submit" name="save" value="Save Changes" />
                                    <div>
                                        <br/>
                                    </div>
                                    <input onClick={() => history.push('/settings')} className="saveChangesButton" type="submit" name="cancel" value="Cancel" />
                                </div>
                            </form>
                </div>
            </Popup>
        </div>
    );
};

ChangePassword.propTypes = {
};

const mapStateToProps = ({ authReducer }) => ({
    currentUser: authReducer.user
});

const mapDispatchToProps = {
    doResetGame: () => resetGame(),
    doAbandonGame: () => setNewToken(''),
    doUpdateProfile: (prof) => updateUser(prof)
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
import React, { useEffect, useRef } from 'react';

import './index.css';
import closeIcon from "../../assets/images/icon/X_Button.png"


function useOnClick(ref, handler) {
    useEffect(() => {
        const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };

        document.addEventListener("mousedown", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, []); 
}



const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    const ref = useRef();
    useOnClick(ref, () => handleClose());



    return (
        <div className={showHideClassName}
        >
            <section className="modal-main" ref={ref}>
                <span style={{display:"flex",justifyContent:"flex-end"}}
                >
                <img onClick={handleClose} src={closeIcon} alt="close arrow"></img>
                </span>
                <div className="modal-sub">
                    <div className="modal-inner">
                        {children}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Modal;
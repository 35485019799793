import React, { useState,useRef } from 'react'
import axios from "../../utils/axios/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../api";
import { useHistory } from "react-router"
import Modal from "../Model/Model"
import { showLoader, hideLoader } from "../../Components/Loader/Loader.js";
import './index.css';
import { Formik, Form, Field, ErrorMessage, connect } from 'formik';
import Header from "../../Components/Header/Header";
import HeaderImg from '../../assets/images/Header.png'
import HeaderMobileImg from '../../assets/images/Header-phone.png'
import * as Yup from 'yup'
import moment from "moment"
import Logo from '../../assets/images/49ersLogo.png'
import LogoRight from '../../assets/images/headerRight.png'
import {setTokenInCookies} from '../../store/authReducer/actions';

const Login = ({ history }) => {
    const [startDate, setStartDate] = useState('');
    let resetRegister = null;
    let resetCode = null;
    const [isValide, setValide] = useState(false)
    const [code, setCode] = useState()
    const [show, setShow] = useState()
    const [modelType, setModelType] = useState()
    const [cookieExpire, setCookieExpire] = useState()
    const [isCodeInvalid, setCodeInvalid] = useState(false);
    const [userAllowed, setUserAllowed] = useState(true);
    const hideModal = () => {
        setShow(false)
    };

    const initialValues1 = {
        email: '',
        password: '',
    }
    const initialValues2 = {
        enterCode: '',
    }
    const initialValues3 = {
      email: '',
      password: '',
      confPassword: '',
      fname: '',
      lname: '',
      country: '',
      zip: '',
      dob: new Date(new Date().setFullYear(new Date().getFullYear() - 20)),
    };
    // let history = useHistory();
    let inputEl = useRef(null);
    const onSubmit1 = (values) => {
        if (values) {
            showLoader()
            let data = { email: values.email, password: values.password }
            let url = api.auth.USER_LOGIN
            axios.post(url, data).then(({ status, data }) => {
                const { token } = data;
                if (token) {
                    hideLoader()
                    if (cookieExpire) {
                        var date = new Date();
                        var days = 45;
                        date.setTime(+ date + (days * 86400000));
                        //document.cookie = "token=;"
                        //document.cookie = `token=${token}` + ";expires=" + date.toGMTString() + ";"
                        setTokenInCookies(token);
                        history.push('/games')
                        
                    } else {
                        //document.cookie = "token=;"
                        //document.cookie = `token=${token}`;
                        setTokenInCookies(token)
                        history.push('/games')
                        
                    }

                }
            }).catch(error => {
                console.log(error);
                hideLoader()
                setShow(true)
                setModelType("Login")
            });
        }
    }
    let itemTextClass = ''
    isValide ? itemTextClass = itemTextClass.concat('left-panel-form-group ') : itemTextClass = itemTextClass.concat('selected-left-panel-form-group')
    const onSubmit2 = (values, errors) => {
        if (values) {
            showLoader()
            let data = { code: values.enterCode }
            let url = api.auth.USER_ACTIVATE_CODE
            axios.post(url, data).then(({ status, data }) => {
                const { isValid } = data;
                if (isValid) {
                    hideLoader()
                    setValide(true)
                    setCodeInvalid(false);
                    inputEl.current.focus();
                    setCode(values.enterCode)
                    if (resetCode) {
                        resetCode()
                    }
                }
            }).catch(error => {
                hideLoader()
                setValide(false);
                setCodeInvalid(true);
                //setShow(true)
                //setModelType("Code")
            });
        }
    }
    const onSubmit3 = (values) => {
        if (values) {
            showLoader()
            let data = {
                firstName: values.fname,
                code: code,
                lastName: values.lname,
                email: values.email,
                password: values.password,
                dob: values.dob.toISOString(),
                zipCode: values.zip,
                country: values.country
            }
            
            if(!isUserAllowed(values.dob)) {
                setShow(true)
                setModelType("Under_Aged");
                setUserAllowed(false);
                hideLoader()
                return 
            }
            setUserAllowed(true);
            let url = api.auth.USER_SIGNUP
            axios.post(url, data).then(({ status, data }) => {
                const { token } = data;
                if (token) {
                    setTokenInCookies(token);
                    history.push('/games')
                    hideLoader()
                    setShow(true)
                    setModelType("Register")
                    if (resetRegister) {
                        resetRegister()
                        setValide(false)
                        setCode()
                    }
                }
            }).catch(error => {
                hideLoader()
                console.log(error);
                setModelType("Register-Fail")
                setShow(true)
            });
        }

    }

    const isUserAllowed = (birthdate) => {
        const cutoff = new Date();
        const enteredDate = birthdate ? new Date(birthdate.toString()) : new Date();
        cutoff.setFullYear(cutoff.getFullYear() - 13);      
        return enteredDate <= cutoff;
    }

    const validationSchema1 = Yup.object({
        email: Yup.string().email('*email Invalid')
            .required('*Required !'),
        password: Yup.string().required('*Required !'),
    })
    let validationSchema2 = Yup.object({
        enterCode: Yup.string().required('*Required !')
    })

    const validationSchema3 = Yup.object({
        email: Yup.string().email('*Invalid')
            .required('*Required !'),
        password: Yup.string().required('*Required !'),
        confPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], '*Passwords do not match').required('*Required !'),
        fname: Yup.string().required('*Required !'),
        lname: Yup.string().required('*Required !'),
        country: Yup.string().required('*Required !'),
        //zip: Yup.string().required('*Required !'),
        dob: Yup.string().required('*Required !')

    })
    const rememberCheckBox = (e) => {
        var checkBox = document.getElementById("myCheck");
        if (checkBox.checked === true) {
            setCookieExpire(true)
        } else {
            setCookieExpire(false)
        }
    }
    return (
      <div>
        {/* <div className="login-header">
            </div> */}
        <div className='header' style={{ backgroundImage: `url(${Logo})` }}>
          <div
            className='header header-right'
            style={{ backgroundImage: `url(${LogoRight})` }}
          ></div>
        </div>
        <div className='header-mobile'>
          <div className='section'>
            <div
              className='img'
              style={{ backgroundImage: `url(${Logo})` }}
            ></div>
          </div>
        </div>

        {/* <Header /> */}
        <div className='login-wrapper'>
          <div className='login-wrapper-2'>
            <div className='login-inner-wrapper'>
              <div className='login-container'>
                <h2>Returning Player</h2>
                <span>Already Activated Your Account?</span>
                <span>Login Here</span>
                <Formik
                  initialValues={initialValues1}
                  validationSchema={validationSchema1}
                  onSubmit={onSubmit1}
                >
                  {(formik) => {
                    return (
                      <Form action=''>
                        <div>
                          <div className='errorDiv'>
                            <label>Email</label>
                            <ErrorMessage
                              name='email'
                              component='div'
                              className='errorColorLogin'
                            />
                          </div>
                          <Field
                            type='text'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            name='email'
                            placeholder='Enter your email here'
                            autoComplete='off'
                          />
                        </div>
                        <div>
                          <div className='errorDiv'>
                            <label>Password</label>
                            <ErrorMessage
                              name='password'
                              component='div'
                              className='errorColorLogin'
                            />
                          </div>
                          <Field
                            type='password'
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            name='password'
                            placeholder='Enter your password here'
                            autoComplete='off'
                          />
                        </div>
                        <div className='login-btn'>
                          <button type='submit'> Login</button>
                        </div>
                        <div className='login-bottom'>
                          <div className='login-me'>
                            <input
                              onChange={(e) => rememberCheckBox(e)}
                              type='checkbox'
                              id='myCheck'
                            />
                            <span style={{ float: 'left', fontSize: '14px' }}>
                              Remember Me
                            </span>
                            <a href='/forgot' className='mobile-view'>
                              Forgot Password?
                            </a>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className='left-panel'>
              <span>Activate Your Account</span>
              <div className='login-account'>
                <Formik
                  initialValues={initialValues2}
                  validationSchema={validationSchema2}
                  onSubmit={onSubmit2}
                >
                  {(formik) => {
                    resetCode = formik.resetForm;
                    return (
                      <Form action=''>
                        <div>
                          <div className='errorDiv'>
                            <label htmlFor=''>Enter Activation code</label>
                            <ErrorMessage
                              name='enterCode'
                              component='div'
                              className='errorColor'
                            />
                            {isCodeInvalid ? (
                              <div className='errorColor'>*Code Invalid</div>
                            ) : null}
                          </div>
                          <Field
                            type='text'
                            name='enterCode'
                            placeholder='Enter Code'
                            autoComplete='off'
                          />
                        </div>
                        {code ? (
                          <input
                            type='submit'
                            name='account'
                            value='Account Activated'
                          />
                        ) : (
                          <input
                            className='activateAccountButton'
                            type='submit'
                            name='account'
                            value='Activate Account'
                          />
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </div>
              <span className={itemTextClass}>Create Your Profile</span>
              <Formik
                initialValues={initialValues3}
                validationSchema={validationSchema3}
                onSubmit={onSubmit3}
              >
                {(formik) => {
                  resetRegister = formik.resetForm;
                  return (
                    <Form action=''>
                      <div className={itemTextClass}>
                        <div className='form-group'>
                          <div className='form-1' style={{ margin: '0px' }}>
                            <div className='errorDiv'>
                              <label>First name</label>
                              <ErrorMessage
                                name='fname'
                                component='div'
                                className='errorColor'
                              />
                            </div>
                            <Field
                              type='text'
                              name='fname'
                              placeholder='Enter your first name'
                              autoComplete='off'
                              innerRef={inputEl}
                            />
                          </div>
                          <div className='form-2'>
                            <div className='errorDiv'>
                              <label>Last name</label>
                              <ErrorMessage
                                name='lname'
                                component='div'
                                className='errorColor'
                              />
                            </div>
                            <Field
                              type='text'
                              name='lname'
                              placeholder='Enter your last name'
                              autoComplete='off'
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <div
                            style={{
                              display: 'block',
                              marginBottom: '0px',
                            }}
                          >
                            <div
                              className='errorDiv-1'
                              style={{
                                display: 'inline-flex',
                                marginBottom: '-14px',
                              }}
                            >
                              <label>Email</label>
                              <ErrorMessage
                                name='email'
                                component='div'
                                className='errorColor pd-t'
                              />
                            </div>
                          </div>
                          <Field
                            type='text'
                            name='email'
                            placeholder='Enter your email'
                            autoComplete='off'
                          />
                        </div>
                        <div className='form-group'>
                          <div className='form-1'>
                            <div className='errorDiv'>
                              <label>Country</label>
                              <ErrorMessage
                                name='country'
                                component='div'
                                className='errorColor'
                              />
                            </div>
                            <Field
                              type='text'
                              name='country'
                              placeholder='Enter your country'
                              autoComplete='off'
                            />
                          </div>
                          <div className='form-2'>
                            <div className='errorDiv'>
                              <label>Date of Birth</label>
                              <ErrorMessage
                                name='dob'
                                component='div'
                                className='errorColor'
                              />
                              {!userAllowed ? (
                                <div className='errorColor'>*Invalid</div>
                              ) : null}
                            </div>
                            <DatePicker
                              autoComplete='off'
                              dateFormat='dd/MM/yyyy'
                              name='dob'
                              placeholderText='DD/MM/YYYY'
                              selected={formik.values.dob}
                              onChange={(date) =>
                                formik.setFieldValue('dob', date)
                              }
                              showMonthDropdown
                              showYearDropdown
                              maxDate={new Date()}
                              dropdownMode='select'
                            />
                          </div>
                        </div>
                        <div>
                          <div style={{ display: 'block' }}>
                            <div
                              className='errorDiv-1'
                              style={{
                                display: 'inline-flex',
                                marginBottom: '-14px',
                              }}
                            >
                              <label>Create Password</label>
                              <ErrorMessage
                                name='password'
                                component='div'
                                className='errorColor pd-t'
                              />
                            </div>
                            <Field
                              type='password'
                              name='password'
                              placeholder='Enter your create password'
                              autoComplete='off'
                            />
                          </div>
                        </div>
                        <div>
                          <div className='errorDiv'>
                            <label>Confirm Password</label>
                            <ErrorMessage
                              name='confPassword'
                              component='div'
                              className='errorColor'
                            />
                          </div>
                          <Field
                            type='password'
                            name='confPassword'
                            placeholder='Enter your confirm password'
                            autoComplete='off'
                          />
                        </div>
                        <div className='profile-btn'>
                          {code ? (
                            <input
                              className='startGameButton'
                              type='submit'
                              name='login'
                              value='Save Profile | Start Game'
                            />
                          ) : (
                            <input
                              type='submit'
                              name='login'
                              value='Save Profile | Start Game'
                            />
                          )}
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
        <Modal show={show} handleClose={hideModal}>
          {modelType === 'Login' ? (
            <div className='login-Error-Popup'>
              <span>Login Failed</span>
              <a href='/login'>Try again</a>
              <a href='/forgot'>Forgot Password</a>
            </div>
          ) : modelType === 'Code' ? (
            <div className='login-Error-Popup'>
              <span>Code Invalid</span>
            </div>
          ) : modelType === 'Register' ? (
            <div className='login-Error-Popup'>
              <span>Registered Successfully</span>
            </div>
          ) : modelType === 'Under_Aged' ? (
            <div className='login-Error-Popup'>
              <span>You are not allowed to play the game</span>
            </div>
          ) : (
            <div className='login-Error-Popup'>
              <span>email already used</span>
            </div>
          )}
        </Modal>
      </div>
    );
}

export default Login

import React from "react";
import "./index.css";
import HeaderImg from '../../assets/images/Header.png'
import HeaderMobileImg from '../../assets/images/Header-mobile.png'
import Logo from '../../assets/images/49ersLogo.png'
import LogoRight from '../../assets/images/headerRight.png'

const Header = () => {
  return (
    <>
      <div className="header" style={{ backgroundImage: `url(${Logo})` }}>
        <div className="header header-right" style={{ backgroundImage: `url(${LogoRight})` }}>
        </div>
      </div>
      <div className="header-mobile">
        <div className="section">
          <div className="img" style={{ backgroundImage: `url(${Logo})` }}></div>
          <div className="tropy"></div>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {};

export default Header;
